/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client.diagram

import scala.collection.immutable._
import scala.util.chaining._
import scala.concurrent.ExecutionContext.Implicits.global

import org.scalajs.dom
import org.scalajs.dom.html.Div
import com.dalineage.common
import common.DBImageObjects._
import common.adt.DBImageADT._

import com.dalineage.client
import client.diagram.DiagramOps
import client.UserActions._
import client.LineageUserActions._

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary
import cats.syntax.all._

import client.CSS.cssStyle
import client.UserActions
import com.dalineage.client.IDBDatabaseUtils.JsObjectUtils
import scala.util.Failure
import scala.util.Success

object LineageTemplate {

  def apply(userActionFn:UserAction => Unit)(implicit diagram:go.Diagram)
      : go.Part = {

    //item template
    val itemShape = new go.Shape
    itemShape.desiredSize = new go.Size(10, 10)
    itemShape.strokeJoin = typings.gojs.gojsStrings.round
    itemShape.strokeWidth = 3
    itemShape.stroke = null
    itemShape.margin = 4
    itemShape.bind(new go.Binding("figure", "figure"))
    itemShape.bind(new go.Binding("fill", "color"))
    itemShape.bind(new go.Binding("stroke", "color"))

    val itemText = new go.TextBlock
    itemText.stroke = "#333333"
    itemText.font = "bold 14px sans-serif"
    itemText.bind(new go.Binding("text", "name"))

    val nodeShapeFill = "white"

    val indentShape = new go.Shape
    indentShape.height = 12
    indentShape.fill = nodeShapeFill
    indentShape.stroke = null
    indentShape.bind(new go.Binding("width", "indent"))

    val structExpandButton = go.GraphObject.make[go.Shape]("PanelExpanderButton", "STRUCT")
    structExpandButton.row = 0
    structExpandButton.alignment = go.Spot.TopRight
    structExpandButton.bind(new go.Binding("visible", "isstruct"))

    val columnPanel = new go.Panel("Horizontal")
    columnPanel.add(indentShape)
    columnPanel.add(itemShape)
    columnPanel.add(itemText)
    columnPanel.stretch = go.GraphObject.Horizontal.asInstanceOf[go.EnumValue]
    columnPanel.add(structExpandButton)

    val structPanel = new go.Panel("Vertical")
    structPanel.bind(new go.Binding("itemArray", "struct"))
    structPanel.stretch = go.GraphObject.Horizontal.asInstanceOf[go.EnumValue]
    structPanel.name = "STRUCT"
    structPanel.bind(new go.Binding("visible", "expanded").makeTwoWay())

    val itemTemplate = new go.Panel("Vertical")
    itemTemplate.add(columnPanel)
    itemTemplate.add(structPanel)
    itemTemplate.stretch = go.GraphObject.Horizontal.asInstanceOf[go.EnumValue]
    itemTemplate.bind(new go.Binding("portId", "key"))
    itemTemplate.fromSpot = go.Spot.RightCenter
    itemTemplate.toSpot = go.Spot.LeftCenter
    itemTemplate.isActionable = true

    val clickEvent:js.Function2[go.InputEvent,itemTemplate.type,Unit] = {
      case (inputEvent, param2) =>

        val panel: go.Panel = param2
        val data = DiagramOps.toStringDictionary(panel.data)

        userActionFn(GoJSDiagram.ColumnSelected(Some((data, param2))))

        inputEvent.handled = true
    }

    itemTemplate.click = clickEvent

    structPanel.itemTemplate = itemTemplate

    //node template

    val textBlock = new go.TextBlock
    textBlock.row = 0
    textBlock.alignment = go.Spot.Center
    textBlock.margin = new go.Margin(0, 24, 0, 2)
    textBlock.font = cssStyle("table-node").map(_.font).getOrElse("bold 16px sans-serif")
    textBlock.bind( new go.Binding("text", "caption"))
    val portFn:scala.scalajs.js.Function2[Any,Any,Any] = {
      case (v1, v2) => s"$v1.head"
    }
    textBlock.bind(new go.Binding("portId", "key", portFn))
    textBlock.fromSpot = go.Spot.RightCenter
    textBlock.toSpot = go.Spot.LeftCenter

    val tableExpandButton = go.GraphObject.make[go.Shape]("PanelExpanderButton", "COLUMNLIST")
    tableExpandButton.row = 0
    tableExpandButton.alignment = go.Spot.TopRight

    val vertical = new go.Panel("Vertical")
    vertical.name = "COLUMNLIST"
    vertical.row = 1
    vertical.padding = 3
    vertical.alignment = go.Spot.TopLeft
    vertical.defaultAlignment = go.Spot.Left
    vertical.stretch = go.GraphObject.Horizontal.asInstanceOf[go.EnumValue]
    vertical.itemTemplate = itemTemplate
    vertical.bind(new go.Binding("itemArray", "fields"))
    vertical.bind(new go.Binding("visible", "expanded", (), TableExpandFn))

    val table = new go.Panel("Table")

    val toolTip = new go.Adornment("Auto")
    toolTip.padding = 5
    toolTip.pickable = false
    val tooltipTextBlock = new go.TextBlock
    tooltipTextBlock.background = "#FFFFCC"
    tooltipTextBlock.opacity = 0.75
    tooltipTextBlock.bind( new go.Binding("text","tooltip") )
    toolTip.add(tooltipTextBlock)

    table.toolTip = toolTip


    val rowColDef = table.getRowDefinition(0)
    rowColDef.sizing = go.RowColumnDefinition.None

    table.margin = 8
    table.stretch =  go.GraphObject.Fill
    table.add(textBlock)
    table.add(tableExpandButton)
    table.add(vertical)

    val nodeShape = new go.Shape
    nodeShape.figure = "Rectangle"
    nodeShape.fill = nodeShapeFill
    nodeShape.stroke = cssStyle("table-node").map(_.border).getOrElse("#333333")

    val nodeTemplate = new go.Node("Auto")
    nodeTemplate.selectionAdorned = true
    nodeTemplate.resizable = true
    nodeTemplate.fromSpot = go.Spot.AllSides
    nodeTemplate.toSpot = go.Spot.AllSides
    nodeTemplate.isShadowed = true
    nodeTemplate.shadowOffset = new go.Point(3, 3)
    nodeTemplate.shadowColor = cssStyle("table-node").map(_.borderColor).getOrElse("red")
    nodeTemplate.copyable = false
    nodeTemplate.deletable = false
    nodeTemplate.bind(new go.Binding("visible", "visible").makeTwoWay())

    nodeTemplate.add(nodeShape)
    nodeTemplate.add(table)

    nodeTemplate.bind(new go.Binding("location", "location").makeTwoWay())

    val modelListener: go.ChangedEvent => Unit =
      event => event.propertyName.toString match {
        case "CommittedTransaction" =>
          event.model match {
            case gm:go.GraphLinksModel =>
              userActionFn(client.diagram.GoJSDiagram.ModelChanged(gm.toJson()))
            case _ =>
          }
        case ee @ _ =>
      }
    diagram.addModelChangedListener(modelListener)

    def diagramListener(changed: Boolean): go.DiagramEvent => Unit =
      event => {
        val subject = event.subject.asInstanceOf[go.Set[go.Part]]
        subject.size match {
          case 0 =>
            if (changed) {
              userActionFn(GoJSDiagram.DiagramNodeSelected(None))
            }
          case 1 =>
            subject.first() match {
              case n: go.Node =>
                n.ports.each{ port =>
                  val links = n.findLinksConnected()
                  links.each{ link =>
                    val dd = DiagramOps.toStringDictionary(link.data)
                    diagram.model.setDataProperty(dd, "hi", changed)
                  }
                }
                val data = n.data.asInstanceOf[StringDictionary[_]]
                if (changed) {
                  userActionFn(GoJSDiagram.DiagramNodeSelected(Some(data)))
                }
              case link: go.Link =>
                if (changed) {
                  userActionFn(client.diagram.GoJSDiagram.DiagramLinkSelected(Some(link)))
                }
              case _ => throw new Exception(s"unknown object type ${subject.first()}")
            }
        }
      }

    diagram.addDiagramListener(
      "ChangedSelection".asInstanceOf[go.DiagramEventName],
      diagramListener(true))

    diagram.addDiagramListener(
      "ChangingSelection".asInstanceOf[go.DiagramEventName],
      diagramListener(false))

    val doKeyDownFn: js.Function2[go.InputEvent,diagram.type,Unit] = { case (inputEvent, param2) =>
      val e = diagram.lastInput.event.asInstanceOf[dom.KeyboardEvent]
      userActionFn(UserActions.KeyboardEvent(e))
    }
    diagram.commandHandler.set("doKeyDown", doKeyDownFn)


    diagram.layout = new go.LayeredDigraphLayout

    nodeTemplate
  }

  // GoJS group expand infinite loop bug workaround (see LineageModel too)
  val TableExpandFn: go.BackConversion = { case (value, sourceData, model) =>
    val expanding = value.asInstanceOf[Boolean]
    val selectedTbl = sourceData.asInstanceOf[go.ObjectData]
    val glModel = model.asInstanceOf[go.GraphLinksModel]
    val selectedTblKey = selectedTbl("key").asInstanceOf[String]

    // update table expanded state
    com.dalineage.client.IDBDatabaseService.expandNode()(selectedTbl, expanding)
      .>>(for {
        fromLinks <- com.dalineage.client.IDBDatabaseService.getLinksByFromKey()(selectedTblKey)
        toLinks <- com.dalineage.client.IDBDatabaseService.getLinksByToKey()(selectedTblKey)
      } yield (fromLinks, toLinks))
      .map{ case (fromLinks, toLinks) =>
        glModel.linkDataArray
          .foldLeft((js.Array[go.ObjectData]() -> js.Array[go.ObjectData]()))(
            (acc, link) =>
              if (link("from") == selectedTblKey) acc._1.addOne(link) -> acc._2
              else if (link("to") == selectedTblKey) acc._1 -> acc._2.addOne(link)
              else acc
          )
          .tap{ case (fromLinksOld, toLinksOld) =>
            fromLinks.length == fromLinksOld.length match
              case true =>
                toLinks.length == toLinksOld.length match
                  case true => ()
                  case false =>
                    glModel.removeLinkDataCollection(toLinksOld)
                    glModel.addLinkDataCollection(toLinks)
              case false =>
                toLinks.length == toLinksOld.length match
                  case true =>
                    glModel.removeLinkDataCollection(fromLinksOld)
                    glModel.addLinkDataCollection(fromLinks)
                  case false =>
                    glModel.removeLinkDataCollection(fromLinksOld ++ toLinksOld)
                    glModel.addLinkDataCollection(fromLinks ++ toLinks)
          }
      }
    value
  }
}

object GroupTemplate {
  def apply(userActionFn:UserAction => Unit): go.Group = {
    val groupExpandButton = go.GraphObject.make[go.Shape]("SubGraphExpanderButton")

    groupExpandButton.margin = 5
    groupExpandButton.alignment = go.Spot.TopRight

    val headerText = new go.TextBlock
    headerText.alignment = go.Spot.Left
    headerText.editable = true
    headerText.margin = 5
    headerText.font = cssStyle("group-header").map(_.font).getOrElse("normal 13px sans-serif")
    headerText.opacity = 0.75
    headerText.stroke = cssStyle("group-header").map(_.color).getOrElse("black")
    headerText.bind( new go.Binding("text","caption") )

    val headerPanel = new go.Panel("Horizontal")
    headerPanel.stretch = go.GraphObject.Horizontal.asInstanceOf[go.EnumValue]
    headerPanel.background = cssStyle("group-header").map(_.backgroundColor).getOrElse("red")

    headerPanel.add(groupExpandButton)
    headerPanel.add(headerText)

    val placeholder = new go.Placeholder
    placeholder.padding = 5
    placeholder.alignment = go.Spot.TopLeft

    val vertical = new go.Panel("Vertical")
    vertical.add(headerPanel)
    vertical.add(placeholder)

    val rectangle = new go.Shape
    rectangle.figure = "Rectangle"
    rectangle.fill = null
    rectangle.stroke = cssStyle("group-node").map(_.borderColor).getOrElse("red")
    rectangle.strokeWidth = 2

    val group = new go.Group("Auto")
    group.layout = new go.LayeredDigraphLayout

    val toolTip = new go.Adornment("Auto")
    toolTip.padding = 5
    toolTip.pickable = false
    val textBlock = new go.TextBlock
    textBlock.background = "#FFFFCC"
    textBlock.opacity = 0.75
    textBlock.bind( new go.Binding("text","tooltip") )
    toolTip.add(textBlock)

    group.toolTip = toolTip

    group.background = "transparent"
    group.ungroupable = false
    // group.bind(new go.Binding("isSubGraphExpanded", "expanded").makeTwoWay())
    group.bind(new go.Binding("isSubGraphExpanded", "expanded", (), GroupExpandFn))

    group.bind(new go.Binding("location", "location").makeTwoWay())

    group.add(rectangle)
    group.add(vertical)

    group
  }

  val GroupExpandFn: go.BackConversion = { case (value, sourceData, model) =>
    val expanding: Boolean = value.asInstanceOf[Boolean]
    val tabKey = sourceData.asInstanceOf[go.ObjectData]("key").asInstanceOf[String]
    println(s"GroupExpandFn: $expanding")
    println(s"GroupExpandFn: $tabKey")
    if (expanding) {
      model.asInstanceOf[go.GraphLinksModel].findNodeDataForKey(tabKey).foreach { nodeData =>
        println(s"GroupExpandFn: $nodeData")
        // nodeData("expanded") = true
      }
    }
    value
  }
}

  //   val TableExpandFn: go.BackConversion = { case (value, sourceData, model) =>
  //   val expanding = value.asInstanceOf[Boolean]
  //   val tabKey = sourceData.asInstanceOf[go.ObjectData]("key").asInstanceOf[String]

  //   if (expanding) {
  //     val glModel = model.asInstanceOf[go.GraphLinksModel]
  //     getAndRemoveLinksOfCollapsedTable(model, "linksFromCollapsedTables", tabKey).foreach { link =>
  //       glModel.setFromPortIdForLinkData(link, link("fromPort-off").asInstanceOf[String])
  //       link.remove("fromPort-off")
  //     }
  //     getAndRemoveLinksOfCollapsedTable(model, "linksToCollapsedTables", tabKey).foreach { link =>
  //       glModel.setToPortIdForLinkData(link, link("toPort-off").asInstanceOf[String])
  //       link.remove("toPort-off") tap println
  //     }
  //   }
  //   value
  // }

  // private def getAndRemoveLinksOfCollapsedTable(
  //   model: go.Model,
  //   linksListName: String,
  //   tabKey: String
  // ): js.Array[go.ObjectData] =
  //   model.modelData.get(linksListName).fold(js.Array()) { a =>
  //     a.asInstanceOf[go.ObjectData] pipe { od =>
  //       od.get(tabKey).fold(js.Array()) { a =>
  //         od.remove(tabKey) tap println
  //         a.asInstanceOf[js.Array[go.ObjectData]] tap println
  //       }
  //     }
  //   }

object LinkTemplate {
  def apply(): go.Link = {
    val linkTemplate = new go.Link
    linkTemplate.relinkableFrom = true
    linkTemplate.relinkableTo = true
    linkTemplate.toShortLength = 4
    linkTemplate.fromShortLength = 2

    val hiFn:scala.scalajs.js.Function2[Any,Any,Any] = {
      case (v1, v2) => v1 match {
          case true => 3
          case false => 1
      }
    }

    val shape1 = new go.Shape
    shape1.bind(new go.Binding("stroke", "color"))
    shape1.bind(new go.Binding("strokeWidth", "hi", hiFn))
    val shape2 = new go.Shape
    shape2.toArrow = "Standard"
    shape2.stroke = null
    shape2.bind(new go.Binding("stroke", "color"))
    shape2.bind(new go.Binding("fill", "color"))

    linkTemplate.add(shape1)
    linkTemplate.add(shape2)

    linkTemplate
  }
}
