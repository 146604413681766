/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.common.adt

import io.circe.Codec

/*  Inherited attributes. Contains options for processing */
object ProcessingInfoADT {
  import Dialects._

  sealed trait AnalyzerMode
  case object StrictMode extends AnalyzerMode
  case object AutocreateMode extends AnalyzerMode
  case object UnknownMode extends AnalyzerMode

  case class ProcessingInfo(
    sourceSpec: SourceSpec,
    index: Int,
    startTime: Long,
  ) derives Codec.AsObject

  case class PassInfo(
    batchInfo: BatchInfoADT.BatchInfo,
    passNr: Int,
  ) {
    def strictFirstThenAC = batchInfo.autoCreate && batchInfo.analyzer.strict
    lazy val sourcesSize = batchInfo.sources.size
  }

  case class SourceSpec(
    name: String,
    modelPosition: Option[Int],
    defaultDatabase: Option[String],
    defaultSchema: Option[String],
    isExportedDbModel: Boolean,
    exclude: List[String],
  ) derives Codec.AsObject
}

/* Sythetised attributes. Contains results of processing */
object ProcessingStatsADT {

  case class SourceProcessingStats(
    processingInfo: ProcessingInfoADT.ProcessingInfo,
    parserTime: Long,
    analyzerTime: Long,
    parsingError: Option[String],
    analyzerError: Option[String],
    queries: List[String],
  ) derives Codec.AsObject

  case class BatchProcessingStats(
    totalFiles: Int,
    relationCount: Int,
    columnCount: Int,
    sourceStats: List[SourceProcessingStats],
    parserErrors : Int = 0,
    analyzerErrors : Int = 0,
    processingEnd: Option[Long] = None,
    analyzerMode: Option[ProcessingInfoADT.AnalyzerMode] = None,
    isModeCompleted: Option[Boolean] = None
  ) derives Codec.AsObject
}
