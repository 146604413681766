/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import scala.util.chaining._

import scala.concurrent.{Promise, Future}
import scala.concurrent.ExecutionContext.Implicits.global
import cats.syntax.all._
import typings.gojs.{mod => go}

object IDBDatabaseDriver {
  import IDBDatabaseUtils._
  import IDBDatabaseADT._
  import scala.scalajs.js
  import js.JSConverters._
  import org.scalajs.dom
  import dom.window.indexedDB
  import dom.{
    console,
    IDBDatabase,
    IDBVersionChangeEvent,
    Event,
    IDBOpenDBRequest,
    IDBEvent,
    IDBCursorWithValue,
    ErrorEvent,
    IDBCreateObjectStoreOptions,
    IDBCreateIndexOptions,
    IDBObjectStore,
    IDBIndex,
    IDBTransactionMode,
    IDBTransaction,
    IDBRequest,
    IDBValue,
    IDBKey,
    IDBKeyRange,
    EventInit
  }

  val version: Int = 5
  val defaultDBName: String = "GOJSDB"

  def open(dbName: String = defaultDBName, verbose: Boolean): Future[IDBDatabase] = {

    val promise = Promise[IDBDatabase]()

    indexedDB
      .toOption
      .map(
        _.open(dbName, version)
          .tap(_.onsuccess = (event: IDBEvent[IDBDatabase]) =>
            event
              .pipe(_.target.result)
              .tap((idb: IDBDatabase) => console.log(s"Database $dbName opened with version ${idb.version}"))
              .pipe(promise.success)
          )
          .tap(_.onerror = (event: ErrorEvent) =>
            event
              .tap(logEvent(console.error, verbose))
              .pipe(getErrorMsg(_))
              .pipe(msg => s"Failed to open database: $msg")
              .tap(console.error(_))
              .pipe(msg => promise.failure(new Exception(msg)))
          )
          .tap(_.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            console.log(s"Upgrading database $dbName from version ${event.oldVersion} to version ${event.newVersion}")
            event.target.result
              .pipe(createStore(GoJSStoreName.nodes, Some("key")))
              .pipe(createIndex("groupIDX", "group"))

            event.target.result
              .pipe(createStore(GoJSStoreName.links, Some("key"), true))
              .pipe(createIndex("fromIDX", "from"))
              .pipe(createIndex("toIDX", "to"))
          })
          .tap(_.onblocked = (event: IDBVersionChangeEvent) =>
            event
              .tap(logEvent(console.error, verbose))
              .pipe(_ => s"Database $dbName is blocked!")
              .tap(console.error(_))
          )
          .tap(_.addEventListener("close", (_: Event) => console.log(s"Database $dbName closed.")))
      )
      .getOrElse(promise.failure(new Exception("IndexedDB not supported")))

    promise.future
  }

  private def add(value: go.ObjectData, ignoreConstraintError: Boolean, verbose: Boolean)(store: IDBObjectStore): IDBRequest[IDBObjectStore, IDBKey] =
    store
      .add(value)
      .tap(_.onsuccess = (event: IDBEvent[IDBKey]) =>
        event
          .pipe(_.target.result)
          .tap(result => if (verbose) console.log(s"Value added into ${store.name} with key: $result"))
      )
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .tap(_.stopPropagation())  // stop error from bubbling up to the transaction.onerror handler
          .pipe(e =>
            getEventError(Some(value))(e)
              .tap(error => {
                if (ignoreConstraintError && isConstraintError(error)) {
                  e.preventDefault() // cancel the error event - do not abort the transaction
                }
              })
          )
          .tap(error => console.error(s"Failed to add value into ${store.name}: ${getErrorMsg(error)}")) // log the error
      )

  def addOne(value: go.ObjectData, storeName: GoJSStoreName, verbose: Boolean)(idb: IDBDatabase): Future[Boolean] = {
    val promise = Promise[Boolean]()

    idb
      .transaction(storeName, IDBTransactionMode.readwrite)
      .pipe((transaction: IDBTransaction) => {
        transaction
          .tap(_.onabort = (event: Event) =>
            event
              .tap(logEvent(console.warn, verbose))
              .tap(_ => console.warn(s"Transaction ADD ONE aborted!"))
              .pipe(e => promise.failure(new Exception(s"Transaction ADD ONE aborted! ${e.toString()}")))
          )
          .tap(_.oncomplete = (event: Event) =>
            console.info(s"Transaction ADD ONE completed!")
              .pipe(_ => promise.success(true))
          )
          .pipe(_.objectStore(storeName))
          .pipe(add(value, false, verbose))
      })

    promise.future
  }

  def addBatch(values: js.Array[go.ObjectData], storeName: GoJSStoreName, ignoreConstraintError: Boolean = false, verbose: Boolean = false)(idb: IDBDatabase): Future[Boolean] = {
    val promise = Promise[Boolean]()

    idb
      .transaction(storeName, IDBTransactionMode.readwrite)
      .tap(_.onabort = (event: Event) =>
        event
          .tap(logEvent(console.warn, verbose))
          .tap(_ => console.warn(s"Transaction ADD BATCH into $storeName aborted!"))
          .pipe(e => promise.failure(new Exception(s"Transaction ADD BATCH aborted! ${e.toString()}")))
      )
      .tap(_.oncomplete = (event: Event) =>
        console.info(s"Transaction ADD BATCH into $storeName completed!")
          .pipe(_ => promise.success(true))
      )
      .pipe(_.objectStore(storeName))
      .tap(_ => console.log(s"Adding ${values.length} values into store $storeName."))
      .pipe(store => values.foreach(add(_, ignoreConstraintError, verbose)(store)))

    promise.future
  }

  def getOne(storeName: GoJSStoreName, optIdxName: Option[String] = None, key: IDBKey,  verbose: Boolean = false)(idb: IDBDatabase): Future[Option[IDBValue]] = {
    val promise = Promise[Option[IDBValue]]()
    var result: Option[IDBValue] = None

    idb
      .transaction(storeName, IDBTransactionMode.readonly)
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Transaction GET ALL from $storeName failed with message: $msg")
          .tap(console.error(_))
      )
      .tap(_.onabort = (event: Event) =>
        event
          .tap(logEvent(console.warn, verbose))
          .pipe(e => s"Transaction GET ALL from $storeName aborted! ${e.toString()}")
          .tap(console.warn(_))
          .pipe(new Exception(_))
          .pipe(promise.failure)
      )
      .tap(_.oncomplete = (event: Event) =>
        console.info(s"Transaction GET ALL from $storeName completed!")
          .pipe(_ => promise.success(result))
      )
      .objectStore(storeName)
      .pipe(store =>
        optIdxName.fold
          (store.get(IDBKeyRange.only(key)))
          (store.index(_).get(IDBKeyRange.only(key)))
      )
      .tap(_.onsuccess = (event: IDBEvent[IDBValue]) =>
        event
          .pipe(_.target.result)
          .pipe(Option(_))
          .tap(result = _)
      )
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Failed to get all values: $msg")
          .tap(console.error(_))
      )

    promise.future
  }

  def getAll(storeName: GoJSStoreName, optIdxName: Option[String] = None, optRange: Option[IDBKeyRange] = None, verbose: Boolean = false)(idb: IDBDatabase): Future[js.Array[IDBValue]] = {
    val promise = Promise[js.Array[IDBValue]]()
    var result: js.Array[IDBValue] = js.Array[IDBValue]()

    idb
      .transaction(storeName, IDBTransactionMode.readonly)
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Transaction GET ALL from $storeName failed with message: $msg")
          .tap(console.error(_))
      )
      .tap(_.onabort = (event: Event) =>
        event
          .tap(logEvent(console.warn, verbose))
          .pipe(e => s"Transaction GET ALL from $storeName aborted! ${e.toString()}")
          .tap(console.warn(_))
          .pipe(new Exception(_))
          .pipe(promise.failure)
      )
      .tap(_.oncomplete = (event: Event) =>
        console.info(s"Transaction GET ALL from $storeName completed!")
          .pipe(_ => promise.success(result))
      )
      .objectStore(storeName)
      .pipe(store =>
        optIdxName.fold
          (store.getAll(optRange.orUndefined))
          (store.index(_).getAll(optRange.orUndefined))
      )
      .tap(_.onsuccess = (event: IDBEvent[js.Array[Any]]) =>
        event
          .pipe(_.target.result.asInstanceOf[js.Array[IDBValue]])
          .tap(values => if (verbose) values.foreach(console.log(_)) )
          .tap(values => console.log(s"Got ${values.length} values from store $storeName."))
          .tap(result.addAll)
      )
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Failed to get all values: $msg")
          .tap(console.error(_))
      )

    promise.future
  }

  def getKeyWithFilter(storeName: GoJSStoreName, optIdxName: Option[String] = None, optRange: Option[IDBKeyRange] = None, filter: IDBValue => Boolean,  verbose: Boolean = false)(idb: IDBDatabase): Future[js.Array[IDBKey]] = {
    val promise = Promise[js.Array[IDBKey]]()
    var result: js.Array[IDBKey] = js.Array[IDBKey]()

    idb
      .transaction(storeName, IDBTransactionMode.readonly)
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Transaction GET ALL KEYS from $storeName failed with message: $msg")
          .tap(console.error(_))
      )
      .tap(_.onabort = (event: Event) =>
        event
          .tap(logEvent(console.warn, verbose))
          .pipe(e => s"Transaction GET ALL KEYS from $storeName aborted! ${e.toString()}")
          .tap(console.warn(_))
          .pipe(new Exception(_))
          .pipe(promise.failure)
      )
      .tap(_.oncomplete = (event: Event) =>
        console.info(s"Transaction GET ALL KEYS from $storeName completed!")
          .pipe(_ => promise.success(result))
      )
      .objectStore(storeName)
      .pipe(store =>
        optIdxName.fold
          (store)
          (store.index(_))
      )
      .pipe(_.openCursor(optRange.orUndefined))
      .tap(_.onsuccess = (event: IDBEvent[IDBCursorWithValue[IDBObjectStore | IDBIndex]]) =>
        event
          .pipe(_.target.result)
          .tap((cursor: IDBCursorWithValue[IDBObjectStore | IDBIndex]) => {
            Option(cursor)
              .map(
                _.tap(
                  _.value.asInstanceOf[IDBValue]
                  .tap{
                    case value: IDBValue if filter(value) => result.addOne(value.asInstanceOf[go.ObjectData].getValue("key", ""))
                    case _ => ()
                  }
                )
                .tap(_.continue())
              )
          })
      )
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Failed to GET ALL KEY values with filter: $msg")
          .tap(console.error(_))
      )

    promise.future
  }

  def getAllWithFilter(storeName: GoJSStoreName, optIdxName: Option[String] = None, optRange: Option[IDBKeyRange] = None, filter: IDBValue => Boolean,  verbose: Boolean = false)(idb: IDBDatabase): Future[js.Array[IDBValue]] = {
    val promise = Promise[js.Array[IDBValue]]()
    var result: js.Array[IDBValue] = js.Array[IDBValue]()

    idb
      .transaction(storeName, IDBTransactionMode.readonly)
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Transaction GET ALL from $storeName failed with message: $msg")
          .tap(console.error(_))
      )
      .tap(_.onabort = (event: Event) =>
        event
          .tap(logEvent(console.warn, verbose))
          .pipe(e => s"Transaction GET ALL from $storeName aborted! ${e.toString()}")
          .tap(console.warn(_))
          .pipe(new Exception(_))
          .pipe(promise.failure)
      )
      .tap(_.oncomplete = (event: Event) =>
        console.info(s"Transaction GET ALL from $storeName completed!")
          .pipe(_ => promise.success(result))
      )
      .objectStore(storeName)
      .pipe(store =>
        optIdxName.fold
          (store)
          (store.index(_))
      )
      .pipe(_.openCursor(optRange.orUndefined))
      .tap(_.onsuccess = (event: IDBEvent[IDBCursorWithValue[IDBObjectStore | IDBIndex]]) =>
        event
          .pipe(_.target.result)
          .tap((cursor: IDBCursorWithValue[IDBObjectStore | IDBIndex]) => {
            Option(cursor)
              .map(
                _.tap(
                  _.value.asInstanceOf[IDBValue]
                  .tap(console.info(_))
                  .tap{
                    case value: IDBValue if filter(value) => result.addOne(value)
                    case _ => ()
                  }
                )
                .tap(_.continue())
              )
          })
      )
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Failed to get all values with filter: $msg")
          .tap(console.error(_))
      )

    promise.future
  }

  def getLinks(optIdxName: Option[String] = None, optRange: Option[IDBKeyRange] = None, expandedTbls: js.Array[IDBKey], verbose: Boolean = false)(idb: IDBDatabase): Future[js.Array[IDBValue]] = {
    val promise = Promise[js.Array[IDBValue]]()
    var result: js.Array[IDBValue] = js.Array[IDBValue]()

    def isTableExpanded(key: IDBKey): Boolean = expandedTbls.contains(key)

    idb
      .transaction(GoJSStoreName.links, IDBTransactionMode.readonly)
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Transaction GET Links failed with message: $msg")
          .tap(console.error(_))
      )
      .tap(_.onabort = (event: Event) =>
        event
          .tap(logEvent(console.warn, verbose))
          .pipe(e => s"Transaction GET Links aborted! ${e.toString()}")
          .tap(console.warn(_))
          .pipe(new Exception(_))
          .pipe(promise.failure)
      )
      .tap(_.oncomplete = (event: Event) =>
        console.info(s"Transaction GET Links completed!")
          .pipe(_ => promise.success(result))
      )
      .objectStore(GoJSStoreName.links)
      .pipe(store =>
        optIdxName.fold
          (store)
          (store.index(_))
      )
      .pipe(_.openCursor(optRange.orUndefined))
      .tap(_.onsuccess = (event: IDBEvent[IDBCursorWithValue[IDBObjectStore | IDBIndex]]) =>
        event
          .pipe(_.target.result)
          .tap((cursor: IDBCursorWithValue[IDBObjectStore | IDBIndex]) => {
            Option(cursor)
              .map(
                _.tap(
                  _.value.asInstanceOf[IDBValue]
                  .pipe((value: IDBValue) =>
                    (for {
                      fromTableExpanded <-  value.asInstanceOf[go.ObjectData].getValueOption[String]("from").map(isTableExpanded)
                      toTableExpanded <-  value.asInstanceOf[go.ObjectData].getValueOption[String]("to").map(isTableExpanded)
                    } yield (fromTableExpanded, toTableExpanded))
                    .map(_.tap{
                      case (fromExpanded, toExpanded)  =>
                        if (!fromExpanded && !toExpanded) {
                          // reset fromPort and toPort, only if both tables are collapsed and reduce the number of links
                          value.asInstanceOf[go.ObjectData]
                          .setValue("fromPort", "")
                          .setValue("toPort", "")

                          if (!List("from", "to", "fromPort", "toPort", "color", "hi").forall(key => result.exists(_.asInstanceOf[go.ObjectData].getValueOption[String](key) == value.asInstanceOf[go.ObjectData].getValueOption[String](key))))
                            result.addOne(value)
                        } else {
                          result.addOne(value)
                        }
                        // if (!fromExpanded) value.asInstanceOf[go.ObjectData].setValue("fromPort", "")
                        // if (!toExpanded) value.asInstanceOf[go.ObjectData].setValue("toPort", "")
                        // if (!List("from", "to", "fromPort", "toPort", "color", "hi").forall(key => result.exists(_.asInstanceOf[go.ObjectData].getValueOption[String](key) == value.asInstanceOf[go.ObjectData].getValueOption[String](key))))
                        //   result.addOne(value)
                    })
                  )
                )
                .tap(_.continue())
              )
          })
      )
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Failed to get all values with filter: $msg")
          .tap(console.error(_))
      )

    promise.future
  }

  def getAllAsSet(storeName: GoJSStoreName, optIdxName: Option[String] = None, optRange: Option[IDBKeyRange] = None, keys: List[String],  verbose: Boolean = false)(idb: IDBDatabase): Future[js.Array[IDBValue]] = {
    val promise = Promise[js.Array[IDBValue]]()
    var result: js.Array[IDBValue] = js.Array[IDBValue]()

    idb
      .transaction(storeName, IDBTransactionMode.readonly)
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Transaction GET ALL from $storeName failed with message: $msg")
          .tap(console.error(_))
      )
      .tap(_.onabort = (event: Event) =>
        event
          .tap(logEvent(console.warn, verbose))
          .pipe(e => s"Transaction GET ALL from $storeName aborted! ${e.toString()}")
          .tap(console.warn(_))
          .pipe(new Exception(_))
          .pipe(promise.failure)
      )
      .tap(_.oncomplete = (event: Event) =>
        console.info(s"Transaction GET ALL from $storeName completed!")
          .pipe(_ => promise.success(result))
      )
      .objectStore(storeName)
      .pipe(store =>
        optIdxName.fold
          (store)
          (store.index(_))
      )
      .pipe(_.openCursor(optRange.orUndefined))
      .tap(_.onsuccess = (event: IDBEvent[IDBCursorWithValue[IDBObjectStore | IDBIndex]]) =>
        event
          .pipe(_.target.result)
          .tap((cursor: IDBCursorWithValue[IDBObjectStore | IDBIndex]) => {
            Option(cursor)
              .map(
                _.tap(
                  _.value.asInstanceOf[IDBValue]
                  .tap{
                    case value: IDBValue if keys.forall(key => result.exists(_.asInstanceOf[go.ObjectData].getValueOption[String](key) == value.asInstanceOf[go.ObjectData].getValueOption[String](key))) => ()
                    case value: IDBValue =>
                      keys.foldLeft(org.scalablytyped.runtime.StringDictionary.empty[Any])((acc: go.ObjectData, key: String) => {
                        acc.setValue(key, value.asInstanceOf[go.ObjectData].getValue[String](key, ""))
                      })
                      .tap(result.addOne)
                  }
                )
                .tap(_.continue())
              )
          })
      )
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Failed to get all values with filter: $msg")
          .tap(console.error(_))
      )

    promise.future
  }

  def update(value: go.ObjectData, storeName: GoJSStoreName, verbose: Boolean)(idb: IDBDatabase): Future[IDBKey] = {
    val promise = Promise[IDBKey]()

    idb
      .pipe(createTransaction(storeName, IDBTransactionMode.readwrite, Some("UPDATE"), verbose))
      .objectStore(storeName)
      .put(value)
      .tap(_.onsuccess = (event: IDBEvent[IDBKey]) => promise.success(event.target.result))
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_, Some(value)))
          .pipe(msg => s"Failed to update value: $msg")
          .tap(console.error(_))
          .pipe(msg => promise.failure(new Exception(msg)))
        )

    promise.future
  }

  def clear(storeName: GoJSStoreName, verbose: Boolean)(idb: IDBDatabase): Future[Unit] = {
    val promise = Promise[Unit]()

    idb
      .transaction(storeName, IDBTransactionMode.readwrite)
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Transaction CLEAR failed with message: $msg")
          .tap(console.error(_))
          .pipe(msg => promise.failure(new Exception(msg)))
      )
      .tap(_.onabort = (event: Event) =>
        event
          .tap(logEvent(console.warn, verbose))
          .tap(_ => console.warn(s"Transaction CLEAR aborted!"))
          .pipe(e => promise.failure(new Exception(s"Transaction CLEAR aborted! ${e.toString()}")))
      )
      .tap(_.oncomplete = (event: Event) =>
        console.info(s"Transaction CLEAR completed!")
          .pipe(promise.success)
      )
      .pipe((transaction: IDBTransaction) => {
        transaction
          .objectStore(storeName)
          .clear()
          .tap(_.onsuccess = (_: Event) => console.log(s"Store $storeName cleared."))
          .tap(_.onerror = (event: ErrorEvent) =>
            event
              .tap(logEvent(console.error, verbose))
              .pipe(getErrorMsg(_))
              .pipe(msg => s"Failed to clear store: $msg")
              .tap(console.error(_))
          )
      })

    promise.future
  }

  private def logEvent(logger: Function2[js.Any, Seq[js.Any], Unit], enable: Boolean = true)(event: Event): Unit =
    if (enable) logger(event, Seq.empty[js.Any])

  private def createStore(storeName: GoJSStoreName, keyPath: Option[String] = None, autoIncrement: Boolean = false)(idb: IDBDatabase): IDBObjectStore =
    idb
      .tap{
        case db: IDBDatabase if db.objectStoreNames.nonEmpty && db.objectStoreNames.contains(storeName) => db.deleteObjectStore(storeName)
        case _: IDBDatabase => ()
      }
      .pipe{ (db: IDBDatabase) =>
        keyPath match {
          case Some(key) => db.createObjectStore(storeName, getStoreOptions(key, autoIncrement))
          case None => db.createObjectStore(storeName)
        }
      }

  private def createIndex(idxName: String, keyPath: String, unique: Boolean = false)(store: IDBObjectStore): IDBObjectStore =
    store
      .tap(_.createIndex(idxName, keyPath, getIndexOptions(unique)))

  private def getStoreOptions(keyPath: String, autoIncrement: Boolean = false): IDBCreateObjectStoreOptions =
    js.Dynamic.literal("keyPath" -> keyPath, "autoIncrement" -> autoIncrement).asInstanceOf[IDBCreateObjectStoreOptions]

  private def getIndexOptions(unique: Boolean = false): IDBCreateIndexOptions =
    js.Dynamic.literal("unique" -> unique).asInstanceOf[IDBCreateIndexOptions]

  private def ignoreErrors(ignoreConstraintError: Boolean)(result: js.Array[Either[EventErrorADT, IDBKey]]): js.Array[Either[EventErrorADT, IDBKey]] = {
    if (!ignoreConstraintError) {
      // errors are not ignored => return the result
      return result
    }

    if (!result.exists(_.isLeft)) {
      // no errors => return the result
      return result
    }

    // there are errors and they are ignored => filter out the constraint errors
    result.filter{
      case Left(error) if ignoreConstraintError => !isConstraintError(error)
      // case Left(error) if ??? => !is???Error(error)
      case _ => true
    }
  }

  // possible error codes -> ABORT_ERR: 20; DATA_CLONE_ERR: 25; DOMSTRING_SIZE_ERR: 2; HIERARCHY_REQUEST_ERR: 3; INDEX_SIZE_ERR: 1; INUSE_ATTRIBUTE_ERR: 10; INVALID_ACCESS_ERR: 15; INVALID_CHARACTER_ERR: 5; INVALID_MODIFICATION_ERR: 13; INVALID_NODE_TYPE_ERR: 24; INVALID_STATE_ERR: 11; NAMESPACE_ERR: 14; NETWORK_ERR: 19; NOT_FOUND_ERR: 8; NOT_SUPPORTED_ERR: 9; NO_DATA_ALLOWED_ERR: 6; NO_MODIFICATION_ALLOWED_ERR: 7; QUOTA_EXCEEDED_ERR: 22; SECURITY_ERR: 18; SYNTAX_ERR: 12; TIMEOUT_ERR: 23; TYPE_MISMATCH_ERR: 17; URL_MISMATCH_ERR: 21; VALIDATION_ERR: 16; WRONG_DOCUMENT_ERR: 4;
  private def isConstraintError(event: ErrorEvent): Boolean =
    event
      .pipe(getEventError(None))
      .pipe(isConstraintError)

  private def isConstraintError(error: EventErrorADT): Boolean =
    error.code == 0 && error.name == "ConstraintError"

  private def getEventError(optValue: Option[go.ObjectData])(event: ErrorEvent): EventErrorADT =
    event.target.asInstanceOf[go.ObjectData]
      .getValueOption[go.ObjectData]("error")
      .flatMap((error: go.ObjectData) => for {
        message <- error.getValueOption[String]("message")
        name <- error.getValueOption[String]("name")
        code <- error.getValueOption[Int]("code")
      } yield EventErrorADT(name, message, code, optValue.map(_.toJsonString)))
      .recoverWith(_ => Option(EventErrorADT(message = event.message)))
      .getOrElse(EventErrorADT(name = "Unknown error"))

  private def getErrorMsg(error: EventErrorADT): String =
    s"name: ${error.name}; code: ${error.code}; message: ${error.message}; detail: ${error.value.getOrElse("none")}"

  private def getErrorMsg(event: ErrorEvent, optValue: Option[go.ObjectData] = None): String =
    event
      .pipe(getEventError(optValue))
      .pipe(getErrorMsg)

  private def createTransaction[T](storeName: GoJSStoreName, mode: IDBTransactionMode, txDesc: Option[String] = None, verbose: Boolean)(idb: IDBDatabase): IDBTransaction =
    idb.transaction(storeName, mode)
      .tap(_.oncomplete = (_: Event) =>
        txDesc
          .map(desc => s" $desc ")
          .getOrElse("")
          .pipe(desc => s"Transaction $desc completed.")
          .tap(console.log(_))
      )
      .tap(_.onabort = (_: Event) =>
        txDesc
          .map(desc => s" $desc ")
          .getOrElse("")
          .pipe(desc => s"Transaction $desc aborted.")
          .tap(console.warn(_))
      )
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error, verbose))
          .pipe(getErrorMsg(_))
          .pipe(msg =>
            txDesc
              .map(desc => s"Transaction $desc failed with message: $msg")
              .getOrElse(s"Transaction failed with message: $msg")
          )
          .tap(console.error(_)))

}

object IDBDatabaseService {
  import IDBDatabaseUtils._
  import IDBDatabaseADT._
  import io.circe.{Json, DecodingFailure}
  import scala.scalajs.js
  import js.JSConverters._
  import org.scalajs.dom.{
    console,
    IDBDatabase,
    IDBKeyRange,
    IDBKey,
    IDBValue
  }

  def expandNode(verbose: Boolean = false)(node: go.ObjectData, expand: Boolean): Future[IDBKey] =
    node
      .setValue("expanded", expand)
      .pipe((value: go.ObjectData) =>
        com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
          .flatMap(com.dalineage.client.IDBDatabaseDriver.update(value, GoJSStoreName.nodes, verbose = verbose))
      )

  def addNode(verbose: Boolean = false)(node: go.ObjectData): Future[IDBKey] =
    node
      .pipe((value: go.ObjectData) =>
        com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
          .flatMap(com.dalineage.client.IDBDatabaseDriver.addOne(value, GoJSStoreName.nodes, false))
      )

  def addLink(verbose: Boolean = false)(link: go.ObjectData): Future[IDBKey] =
    link
      .pipe((value: go.ObjectData) =>
        com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
          .flatMap(com.dalineage.client.IDBDatabaseDriver.addOne(value, GoJSStoreName.links, false))
      )

  // not used
  def getNodesByGroupName(verbose: Boolean = false)(groupName: String): Future[js.Array[go.ObjectData]] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap(com.dalineage.client.IDBDatabaseDriver.getAll(GoJSStoreName.nodes, Some("groupIDX"), Some(IDBKeyRange.only(groupName)), verbose = verbose))
      .map(_.asInstanceOf[js.Array[go.ObjectData]])

  // not used
  def getVisibleNodes(verbose: Boolean = false): Future[js.Array[go.ObjectData]] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap(com.dalineage.client.IDBDatabaseDriver.getAllWithFilter(GoJSStoreName.nodes, verbose = verbose, filter = _.asInstanceOf[go.ObjectData].getValue[Boolean]("visible", false)))
      .map(_.asInstanceOf[js.Array[go.ObjectData]])

  def getNodes(verbose: Boolean = false): Future[Iterable[go.ObjectData]] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap(com.dalineage.client.IDBDatabaseDriver.getAll(GoJSStoreName.nodes, verbose = verbose))
      .map(_.asInstanceOf[js.Array[go.ObjectData]])

  def getUniqueLinks(verbose: Boolean = false): Future[Iterable[go.ObjectData]] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap((idb: IDBDatabase) =>
        getExpandedNodeKeys(verbose)(idb)
        .flatMap(expKeys => com.dalineage.client.IDBDatabaseDriver.getLinks(expandedTbls = expKeys, verbose = verbose)(idb))
      )
      .map(_.asInstanceOf[js.Array[go.ObjectData]])

  def getUniqueTableLinks(verbose: Boolean = false)(tableKey: String, expandedTbls: js.Array[IDBKey]): Future[js.Array[go.ObjectData]] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap(com.dalineage.client.IDBDatabaseDriver.getLinks(expandedTbls = expandedTbls, verbose = verbose))
      .map(_.asInstanceOf[js.Array[go.ObjectData]])

  def getLinksByFromKey(verbose: Boolean = false)(key: String): Future[js.Array[go.ObjectData]] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap((idb: IDBDatabase) =>
        com.dalineage.client.IDBDatabaseDriver.getKeyWithFilter(GoJSStoreName.nodes, None, None, _.asInstanceOf[go.ObjectData].getValue[Boolean]("expanded", false), verbose)(idb)
          .flatMap(expKeys => com.dalineage.client.IDBDatabaseDriver.getLinks(optIdxName = Some("fromIDX"), optRange = Some(IDBKeyRange.only(key)), expandedTbls = expKeys, verbose = verbose)(idb))
          .map(_.asInstanceOf[js.Array[go.ObjectData]])
        )

  def getLinksByToKey(verbose: Boolean = false)(key: String): Future[js.Array[go.ObjectData]] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap((idb: IDBDatabase) =>
        com.dalineage.client.IDBDatabaseDriver.getKeyWithFilter(GoJSStoreName.nodes, None, None, _.asInstanceOf[go.ObjectData].getValue[Boolean]("expanded", false), verbose)(idb)
          .flatMap(expKeys => com.dalineage.client.IDBDatabaseDriver.getLinks(optIdxName = Some("toIDX"), optRange = Some(IDBKeyRange.only(key)), expandedTbls = expKeys, verbose = verbose)(idb))
          .map(_.asInstanceOf[js.Array[go.ObjectData]])
        )

  def addLineage(ignoreConstraintError: Boolean = false, verbose: Boolean = false)(gojs: Json): Future[Boolean] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap((idb: IDBDatabase) =>
        List(
          addNodes(ignoreConstraintError, verbose),
          addLinks(verbose)
        )
          .map((unbounded: IDBDatabase => Json => Future[Boolean]) => unbounded(idb))
          .pipe((addDrivers: List[Json => Future[Boolean]]) => Future.sequence(addDrivers.map(_(gojs))))
          .as[Boolean](true)
      )

  def addNodes(ignoreConstraintError: Boolean = false, verbose: Boolean = false)(gojs: Json): Future[Boolean] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap(addNodes(ignoreConstraintError, verbose)(_)(gojs))

  def addLinks(verbose: Boolean = false)(gojs: Json): Future[Boolean] =
    com.dalineage.client.IDBDatabaseDriver.open(verbose = verbose)
      .flatMap(addLinks(verbose)(_)(gojs))

  private def getExpandedNodeKeys(verbose: Boolean = false)(idb: IDBDatabase): Future[js.Array[IDBKey]] =
    com.dalineage.client.IDBDatabaseDriver.getKeyWithFilter(GoJSStoreName.nodes, None, None, _.asInstanceOf[go.ObjectData].getValue[Boolean]("expanded", false), verbose)(idb)

  private def addNodes(ignoreConstraintError: Boolean, verbose: Boolean)(idb: IDBDatabase)(gojs: Json): Future[Boolean] =
    gojs
      .hcursor
      .downField("nodeDataArray")
      .as[List[Json]]
      .fold(
        _.getMessage()
          .pipe(msg => s"Failed to decode nodeDataArray: $msg")
          .tap(console.error(_))
          .pipe(msg => Future.failed(new Exception(msg))),

        _.map(_.nodeToJsObject)
          .toJSArray
          .pipe((nodes: js.Array[go.ObjectData]) =>
            Future.successful(idb)
              .flatTap(com.dalineage.client.IDBDatabaseDriver.clear(GoJSStoreName.nodes, verbose = verbose))
              .flatMap(com.dalineage.client.IDBDatabaseDriver.addBatch(nodes, GoJSStoreName.nodes, ignoreConstraintError = ignoreConstraintError, verbose = verbose))
          )
      )

  private def addLinks(verbose: Boolean)(idb: IDBDatabase)(gojs: Json): Future[Boolean] =
    gojs
      .hcursor
      .downField("linkDataArray")
      .as[List[Json]]
      .fold(
        _.getMessage()
          .pipe(msg => s"Failed to decode linkDataArray: $msg")
          .tap(console.error(_))
          .pipe(msg => Future.failed(new Exception(msg))),

        _.map(_.linkToJsObject)
          .toJSArray
          .pipe((links: js.Array[go.ObjectData]) =>
            Future.successful(idb)
              .flatTap(com.dalineage.client.IDBDatabaseDriver.clear(GoJSStoreName.links, verbose = verbose))
              .flatMap(com.dalineage.client.IDBDatabaseDriver.addBatch(links, GoJSStoreName.links, verbose = verbose))
          )
      )
}

object IDBDatabaseUtils {
  import scala.scalajs.js
  import js.JSConverters._
  import scala.scalajs.js
  import io.circe.Json
  import org.scalablytyped.runtime.StringDictionary

  implicit class JsObjectUtils(jsObject: go.ObjectData) {
    def compare(that: go.ObjectData, keys: List[String] = Nil): Boolean =
      (keys match
        case Nil => jsObject.asInstanceOf[js.Dictionary[js.Any]].keys.toList
        case xs => xs
      )
      .forall(
        key => that.asInstanceOf[js.Dictionary[js.Any]].get(key) == jsObject.asInstanceOf[js.Dictionary[js.Any]].get(key)
      )

    def isInAray(arr: js.Array[go.ObjectData], keys: List[String] = Nil): Boolean =
      arr.exists(_.compare(jsObject, keys))

    def toJsonString = js.JSON.stringify(jsObject)
    def getValueOption[T](key: String): Option[T] =
      jsObject.asInstanceOf[js.Dynamic].selectDynamic(key).asInstanceOf[js.UndefOr[T]].toOption

    def getValue[T](key: String, defaultValue: T): T =
      jsObject.asInstanceOf[js.Dynamic].selectDynamic(key).asInstanceOf[js.UndefOr[T]].toOption.getOrElse(defaultValue)

    def setValue[T](key: String, value: T): go.ObjectData =
      jsObject
        .tap(_.asInstanceOf[js.Dynamic].updateDynamic(key)(value.asInstanceOf[js.Any]))

    def nodeJsObjectToJson: Json = Json.obj(
      // "struct" ???
      "type" -> jsObject.getValueOption[String]("type").map(Json.fromString).getOrElse(Json.Null),
      "key" -> jsObject.getValueOption[String]("key").map(Json.fromString).getOrElse(Json.Null),
      "caption" -> jsObject.getValueOption[String]("caption").map(Json.fromString).getOrElse(Json.Null),
      "group" -> jsObject.getValueOption[String]("group").map(Json.fromString).getOrElse(Json.Null),
      "tooltip" -> jsObject.getValueOption[String]("tooltip").map(Json.fromString).getOrElse(Json.Null),
      "name" -> jsObject.getValueOption[String]("name").map(Json.fromString).getOrElse(Json.Null),
      "color" -> jsObject.getValueOption[String]("color").map(Json.fromString).getOrElse(Json.Null),
      "tablekey" -> jsObject.getValueOption[String]("tablekey").map(Json.fromString).getOrElse(Json.Null),
      "figure" -> jsObject.getValueOption[String]("figure").map(Json.fromString).getOrElse(Json.Null),
      "fields" -> jsObject.getValueOption[js.Array[go.ObjectData]]("fields")
        .map(
          _.toList
          .map(_.nodeJsObjectToJson)
        )
        .map(Json.fromValues)
        .getOrElse(Json.Null),

      "visible" -> jsObject.getValueOption[Boolean]("visible").map(Json.fromBoolean).getOrElse(Json.Null),
      "isGroup" -> jsObject.getValueOption[Boolean]("isGroup").map(Json.fromBoolean).getOrElse(Json.Null),
      "expanded" -> jsObject.getValueOption[Boolean]("expanded").map(Json.fromBoolean).getOrElse(Json.Null),
      "isstruct" -> jsObject.getValueOption[Boolean]("isstruct").map(Json.fromBoolean).getOrElse(Json.Null),

      "sourceId" -> jsObject.getValueOption[Int]("sourceId").map(Json.fromInt).getOrElse(Json.Null),
      "linefrom" -> jsObject.getValueOption[Int]("linefrom").map(Json.fromInt).getOrElse(Json.Null),
      "columnfrom" -> jsObject.getValueOption[Int]("columnfrom").map(Json.fromInt).getOrElse(Json.Null),
      "lineto" -> jsObject.getValueOption[Int]("lineto").map(Json.fromInt).getOrElse(Json.Null),
      "columnto" -> jsObject.getValueOption[Int]("columnto").map(Json.fromInt).getOrElse(Json.Null),
      "indent" -> jsObject.getValueOption[Int]("indent").map(Json.fromInt).getOrElse(Json.Null),
    )
    .dropNullValues

    def linkJsObjectToJson: Json = Json.obj(
      "from" -> Json.fromString(jsObject.getValue("from", "")),
      "to" -> Json.fromString(jsObject.getValue("to", "")),
      "fromPort" -> Json.fromString(jsObject.getValue("fromPort", "")),
      "toPort" -> Json.fromString(jsObject.getValue("toPort", "")),
      "hi" -> Json.fromBoolean(jsObject.getValue("hi", false)),
      "color" -> Json.fromString(jsObject.getValue("color", ""))
    )
  }

  implicit class JsonUtils(json: Json) {
    def getValueString(key: String, defaultValue: String = ""): String =
      json.hcursor.downField(key).as[String].toOption.getOrElse(defaultValue)

    def getValueBoolean(key: String, defaultValue: Boolean = false): Boolean =
      json.hcursor.downField(key).as[Boolean].toOption.getOrElse(defaultValue)

    def getValueInt(key: String, defaultValue: Int = 0): Int =
      json.hcursor.downField(key).as[Int].toOption.getOrElse(defaultValue)

    private def addValueString(json: Json, key: String)(obj: go.ObjectData): go.ObjectData =
      json.hcursor.downField(key).as[String]
        .map(addValue[String](obj, key))
        .getOrElse(obj)

    private def addValueObjArray(json: Json, key: String)(obj: go.ObjectData): go.ObjectData =
      json.hcursor.downField(key).as[List[Json]]
        .map(_.map(_.nodeToJsObject))
        .map(_.toJSArray)
        .map(addValue[js.Array[go.ObjectData]](obj, key))
        .getOrElse(obj)

    private def addValueBoolean(json: Json, key: String)(obj: go.ObjectData): go.ObjectData =
      json.hcursor.downField(key).as[Boolean]
        .map(addValue[Boolean](obj, key))
        .getOrElse(obj)

    private def addValueInt(json: Json, key: String)(obj: go.ObjectData): go.ObjectData =
      json.hcursor.downField(key).as[Int]
        .map(addValue[Int](obj, key))
        .getOrElse(obj)

    private def addValue[T](obj: go.ObjectData, key: String)(value: T): go.ObjectData =
      obj.setValue[T](key, value)

    // "struct" ???
    def nodeToJsObject: go.ObjectData = StringDictionary[Any](
      "type" -> json.getValueString("type"),
      "key" -> json.getValueString("key"),
      "expanded" -> json.getValueBoolean("expanded"),
    )
      .pipe(stringDictionary => js.Dictionary(stringDictionary.toSeq: _*).asInstanceOf[go.ObjectData])
      .pipe(addValueObjArray(json, "fields"))
      .pipe(addValueString(json, "group"))
      .pipe(addValueString(json, "caption"))
      .pipe(addValueString(json, "name"))
      .pipe(addValueString(json, "tooltip"))
      .pipe(addValueString(json, "color"))
      .pipe(addValueString(json, "tablekey"))
      .pipe(addValueString(json, "figure"))
      .pipe(addValueBoolean(json, "isstruct"))
      .pipe(addValueBoolean(json, "visible"))
      .pipe(addValueBoolean(json, "isGroup"))
      .pipe(addValueInt(json, "sourceId"))
      .pipe(addValueInt(json, "linefrom"))
      .pipe(addValueInt(json, "columnfrom"))
      .pipe(addValueInt(json, "lineto"))
      .pipe(addValueInt(json, "columnto"))
      .pipe(addValueInt(json, "indent"))

    def linkToJsObject: go.ObjectData = StringDictionary[Any](
      "from" -> json.getValueString("from"),
      "to" -> json.getValueString("to"),
      "fromPort" -> json.getValueString("fromPort"),
      "toPort" -> json.getValueString("toPort"),
      "hi" -> json.getValueBoolean("hi"),
      "color" -> json.getValueString("color"),
    )
      .pipe(stringDictionary => js.Dictionary(stringDictionary.toSeq: _*).asInstanceOf[go.ObjectData])
  }
}

object IDBDatabaseADT {
  opaque type GoJSStoreName <: String = String
  object GoJSStoreName {
    val nodes: GoJSStoreName = "nodes"
    val links: GoJSStoreName = "links"
  }

  case class EventErrorADT(
    name: String = "unknown",
    message: String = "unknown",
    code: Int = 0,
    value: Option[String] = None
  )
}
