/* (c) Miroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.common.adt

import io.circe._
import io.circe.KeyEncoder, io.circe.KeyDecoder

object DiagramADT {

  import ADT._

  // this key will be used by gojs library
  type NodeKey = String

  sealed trait DiagramNode {
    val key: NodeKey
    val caption: String
  }

  sealed trait RelationType derives Codec.AsObject {
    override def toString: String = this.getClass.getSimpleName.replace("$", "")
  }
  case object RelationDirect      extends RelationType {
    override def toString: String = "DIRECT"
  }
  case object RelationIndirect    extends RelationType {
    override def toString: String = "INDIRECT"
  }
  case object RelationSemidirect  extends RelationType {
    override def toString: String = "SEMIDIRECT"
  }

  sealed trait RelationClass derives Codec.AsObject {
    override def toString: String = this.getClass.getSimpleName.replace("$", "")
  }
  case object TableVersion        extends RelationClass
  case object DatabaseOrigin      extends RelationClass
  case object DatabaseTarget      extends RelationClass
  case object TempTableOrigin     extends RelationClass
  case object TempTableTarget     extends RelationClass
  case object RelationUnresolved  extends RelationClass
  case object RelationConstant    extends RelationClass
  case object RelationVariable    extends RelationClass
  case object RelationColumnLevel extends RelationClass
  case object InvalidRelation     extends RelationClass

  sealed trait GroupClass derives Codec.AsObject {
    override def toString: String = this.getClass.getSimpleName.replace("$", "")
  }

  case object SourceCode extends GroupClass
  case object Procedure extends GroupClass

  sealed trait RelationKey derives Codec.AsObject {
    val tableKey: NodeKey
    val key: NodeKey
  }

  // case class StructRelationKey(
  //     tableKey: NodeKey,
  //     columnKeyPart: NodeKey,
  //     structPart: List[NodeKey]) extends RelationKey {
  //   val key: NodeKey =
  //     tableKey + "." + columnKeyPart + "." + structPart.mkString(".")
  // }

  case class ColumnRelationKey(
      tableKey: NodeKey,
      columnKeyPart: NodeKey,
      externalId: Option[Int] = None) extends RelationKey {
    val key: NodeKey = tableKey + "." + columnKeyPart
  }

  case class TableRelationKey(
      tableKey: NodeKey) extends RelationKey {
    val key = tableKey
  }

  case class Relation(
      source: RelationKey,
      target: RelationKey,
      typ: RelationType,
      clas: Set[RelationClass],
      sourceFile: Option[String] = None, //requested by dawiso
      path: List[RelationKey] = Nil,
  ) derives Codec.AsObject

  sealed trait TabularNode extends DiagramNode derives Codec.AsObject {
    val codePosition: Option[ADT.Range]
  }

  sealed trait ColumnClass derives Codec.AsObject
  case object DatabaseColumn            extends ColumnClass
  case object DatabaseColumnAutocreated extends ColumnClass
  case object StructDatatype            extends ColumnClass
  case object ConstantSource            extends ColumnClass
  case object UnresolvedSource          extends ColumnClass
  case object ExpandedStruct            extends ColumnClass
  case object CollapsedStruct           extends ColumnClass

  sealed trait TableClass derives Codec.AsObject
  case object ConstantList extends TableClass
  case object VariableList extends TableClass
  case object DatabaseTable extends TableClass

  case class Column(
      key: NodeKey,
      caption: String,
      struct: List[Column],
      externalId: Option[Int],
      clas: Set[ColumnClass] = Set.empty) extends DiagramNode derives Codec.AsObject

  case class Table(
      key: NodeKey,
      caption: String,
      columns: List[Column],
      expanded: Boolean,
      codePosition: Option[ADT.Range],
      clas: Set[TableClass] = Set.empty) extends TabularNode derives Codec.AsObject

  case class Group(
      key: NodeKey,
      caption: String,
      nodes: List[TabularNode],
      codePosition: Option[ADT.Range],
      clas: Set[GroupClass] = Set.empty,
      expanded: Boolean = false) extends TabularNode derives Codec.AsObject

  case class DiagramData(
      relations: Set[Relation],
      nodes: List[TabularNode],
      isComplete: Boolean) derives Codec.AsObject {

    def columnCount: Int = {
      def columnCounter(nodes: List[TabularNode], acc: Int = 0): Int =
          nodes match
            case Nil => acc
            case head :: rest =>
              columnCounter(
                rest,
                head match
                  case Table(_, _, columns, _, _, _) => acc + columns.size
                  case Group(_, _, nodes, _, _, _)      => columnCounter(nodes, acc)
              )

      columnCounter(this.nodes)
    }
  }

  object DiagramData {
    def empty: DiagramData = DiagramData(Set.empty, Nil, true)
  }

}
