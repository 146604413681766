package com.dalineage.client

import scala.collection.immutable._

import com.dalineage.client

import scala.scalajs.js
import org.scalajs.dom
import dom.document
import dom.Window
import dom.html.Div
import dom.html.TextArea

import client.UserActions.UserAction

import scala.scalajs.js
import scala.scalajs.js.timers._
import org.scalablytyped.runtime.StringDictionary

import UserActions._

object Editor {

  case class SelectCode(rowFrom: Int, columnFrom: Int, rowTo: Int, columnTo: Int)
    extends UserAction

  val codeWindow = Window.SingleWindow()

  codeWindow.div.id = "codeWindow"
  codeWindow.div.style.verticalAlign = "top"

  var editor: js.Dynamic = null

  var div: dom.html.Div = null

  var userActionFn: UserAction => Unit = null

  def init(userActionFn: UserAction => Unit): Unit = {

    this.div = codeWindow.div
    this.userActionFn = userActionFn

    div.addEventListener("keydown", { (event: dom.KeyboardEvent) =>
      userActionFn( client.UserActions.KeyboardEvent(event) )
    })
  }

  def open(code: String): Unit = {
    val cm: js.Dynamic = dom.window.asInstanceOf[js.Dynamic].CodeMirror
    editor = cm(div, js.Dynamic.literal(
      "lineNumbers" -> true
    ))
    editor.setSize("100%", "100%");

    editor.getDoc().setValue(code)
  }

  var user = ""
  var batchId = ""
  var sourceId = ""

  //workaround for asynchronous loading of source code
  var selection: (Int, Int, Int, Int) = (0,0,0,0)

  def open( user: String, batchId: String, sourceId: String): Unit = {

    this.user = user
    this.batchId = batchId
    this.sourceId = sourceId

    val cFn: String => Unit = {
      code =>
        div.innerHTML = ""
        Console.msgBox(s"Source code loaded, batchId $batchId sourceId $sourceId")
        open(code)
        if( selection != (0,0,0,0) ) {
          val (lf, cf, lt, ct) = selection
          editor.getDoc().setSelection(js.Dynamic.literal(
            "line" -> lf,
            "ch" -> cf
          ), js.Dynamic.literal(
            "line" -> lt,
            "ch" -> ct
          ))
        }
        selection = (0,0,0,0)
    }
    div.innerHTML = s"Loading code for $user/$batchId/$sourceId"
    userActionFn(UserActions.LoadFile(s"web/code/$user/$batchId/$sourceId",cFn))
  }

  def selectCode(sourceId:String, lf: Int, cf: Int, lt: Int, ct: Int ): Unit = {
    selection = (lf, cf, lt, ct)
    if (sourceId != this.sourceId) {
      Console.msgBox(s"Loading source user $user batchId $batchId sourceId $sourceId")
      open(user, batchId, sourceId)
      this.sourceId = sourceId
    }
    Console.msgBox(s"Selecting code [$lf.$cf] to [$lt.$ct]")
    editor.getDoc().setSelection(js.Dynamic.literal(
      "line" -> lf,
      "ch" -> cf
    ), js.Dynamic.literal(
      "line" -> lt,
      "ch" -> ct
    ))
  }
}
