/* (c) Miroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.common

import scala.collection.immutable._

import io.circe._
import io.circe.syntax._                        // toJson
import io.circe.KeyEncoder, io.circe.KeyDecoder //encoding Map to JSON

object DiagramDataDump {
  import adt.DiagramADT._

  def spc(lvl: Int) = "  " * lvl

  def dumpNode(node: DiagramNode, lvl: Int): String =
    spc(lvl) +
      (node match {
        case g: Group =>
          val pos = g.codePosition.map(p => s"POS [$p]").getOrElse("NO POS")
          s"GROUP ${g.key} $pos " + (if (g.expanded) "+" else "-") +
            g.nodes.map(n =>
              "\n" + dumpNode(n, lvl + 1)
            ).mkString("")
        case t: Table =>
          s"TABLE ${t.key}" +
            (t.clas.size match {
              case 0 => ""
              case _ =>
                s" (${t.clas.map(_.getClass.getSimpleName.replaceAll("\\$", "")).mkString(",")})"
            }) +
            t.columns.map(n => "\n" + dumpNode(n, lvl + 1)).mkString("")
        case c: Column =>
          val structs = c.struct.size match {
            case 0 => ""
            case n => s" STRUCTS $n"
          }
          s"COLUMN ${c.key}$structs" +
            c.struct.map(s => "\n" + dumpNode(s, lvl + 1)).mkString("")
      })

  def apply(dd: DiagramData): String =
    dd.nodes.map(n => dumpNode(n, 0)).mkString("\n")

  def relationsDump(relations: List[Relation], indent: Int = 0): String =
    relations.map {
      case Relation(source, target, typ, cls, _, path) =>
        val sourceStr = source.key
        val srcExtId = source match {
          case ColumnRelationKey(_, _, externalId)  =>
            externalId.map(i => s"[$i]").getOrElse("")
          case _ => ""
        }
        val targetStr = target.key
        val targExtId = target match {
          case ColumnRelationKey(_, _, externalId)  =>
            externalId.map(i => s"[$i]").getOrElse("")
          case _ => ""
        }
        val pathDump = path.map("\n  " + _.key).mkString("")
        " " * indent + s"$sourceStr$srcExtId $targetStr$targExtId " +
         typeDump(typ) + ";" + clsDump(cls) + pathDump
    }.sorted.mkString("\n")

  def typeDump(typ: RelationType): String =
    typ match {
      case RelationDirect     => "DIR"
      case RelationIndirect   => "INDIR"
      case RelationSemidirect => "SEMIDIR"
    }

  def clsDump(cls: Set[RelationClass]): String =
    cls.size match {
      case 0 => " (no class)"
      case _ => " (" + cls.map {
          case InvalidRelation     => "INVAL"
          case TableVersion        => "TBLVER"
          case DatabaseOrigin      => "DBSRC"
          case DatabaseTarget      => "DBTARG"
          case TempTableOrigin     => "TMPSRC"
          case TempTableTarget     => "TMPTARG"
          case RelationUnresolved  => "UNRES"
          case RelationConstant    => "CONST"
          case RelationVariable    => "VAR"
          case RelationColumnLevel => "DBCOL"
        }.toList.sorted.mkString(",") + ")"
    }
}

object RelationOps {
  import adt.DiagramADT._

  def connectLevel( partitionFn: Relation => Boolean, relations: List[Relation] )
    : List[Relation] = {
    relations.partition( partitionFn ) match {
      case (sources, rest) =>
        val sourceByTarget = sources.groupBy(_.target)
        val restBySource = rest.groupBy(_.source)
        val result = sources.flatMap{
          case sourceRel =>
            restBySource.get( sourceRel.target ) match
              case None => sourceRel :: Nil
              case Some(targetRels) =>
                targetRels.map{
                  targetRel =>
                    sourceRel.copy(
                      clas = targetRel.clas ++ sourceRel.clas,
                      target = targetRel.target )
                }
        }
        if( result == sources ) result ++ rest
          else connectLevel( partitionFn, result ++ rest )
    }
  }
}

object DiagramDataSerializer {
  import scala.util.chaining._
  import io.circe._

  import adt.DiagramADT._
  import adt.BatchInfoADT.BatchInfo

  implicit class GoJSSerializer(diagramData: DiagramData) {
    def allNodes = {
      def aux(nodes: List[TabularNode]): List[TabularNode] =
        nodes.flatMap {
          case t: Table => t :: Nil
          case g: Group => g :: aux(g.nodes)
        }

      aux(diagramData.nodes)
    }

    def inByNode: Map[NodeKey, Int] =
      diagramData.relations
        .groupBy(_.target.key)
        .view.mapValues(_.size)
        .toMap

    def outByNode: Map[NodeKey, Int] =
      diagramData.relations
        .groupBy(_.source.key)
        .view.mapValues(_.size)
        .toMap

    def nodeScore: Map[NodeKey, Double] = {
      val inScore = diagramData.inByNode
      val outScore = diagramData.outByNode
      def getScore = score(inScore, outScore)

      def score(inLineage: Map[String, Int], outLineage: Map[String, Int])(key: String): Double =
        (inLineage.getOrElse(key, 0) + 1) / (outLineage.getOrElse(key, 0) + 1)

      diagramData.allNodes
        .map(_.key)
        .map(key => key -> getScore(key))
        .toMap
    }

    private def sortNodes(
        nodes: List[TabularNode],
        score: Map[NodeKey, Double]
      ): List[TabularNode] =
      nodes.sortWith { case (l, r) => score(l.key) < score(r.key) }

    private def relationModel(
        r: Relation,
        // collapsedTables: Set[NodeKey]
      ): Json = {

      def nodePort(rk: RelationKey): String = rk match {
        case ColumnRelationKey(t, c, _) => s"$t.$c"
        case TableRelationKey(t)        => s"$t.head"
      }

      // Workaround for GoJS group expansion infinite loop bug:
      // Links from/to collapsed tables do not contain fromPort/toPort property initially.
      // Later when user expands a table, fromPort/toPort is generated from fromPort-off/toPort-off
      // def portKeySuffix(rk: RelationKey): String =
      //   if (collapsedTables.contains(rk.tableKey)) "-off" else ""

      Json.obj(
        "from" -> Json.fromString(r.source.tableKey),
        "to" -> Json.fromString(r.target.tableKey),
        s"fromPort" -> Json.fromString(nodePort(r.source)),
        s"toPort" -> Json.fromString(nodePort(r.target)),
        // s"fromPort${portKeySuffix(r.source)}" -> Json.fromString(nodePort(r.source)),
        // s"toPort${portKeySuffix(r.target)}" -> Json.fromString(nodePort(r.target)),
        "hi" -> Json.fromBoolean(false),
        "color" -> Json.fromString(relationColor(r))
      )
    }

    private def relationColor(r: Relation): String = r.clas match {
      case c if c.contains(RelationVariable)    => "#cc00ff"
      case c if c.contains(RelationUnresolved)  => "red"
      case c if c.contains(RelationConstant)    => "green"
      case c if c.contains(RelationColumnLevel) => "blue"
      case _ => r.typ match
          case RelationDirect     => "black"
          case RelationIndirect   => "#ff9900"
          case RelationSemidirect => "#ffAA00"
    }

    // def collapsedTables: Set[String] = {
    //   def collectCollapsedTablesKeys(tns: List[TabularNode]): List[NodeKey] =
    //     tns.flatMap {
    //       case t: Table => if (t.expanded) Nil else List(t.key)
    //       case g: Group => collectCollapsedTablesKeys(g.nodes)
    //     }
    //   collectCollapsedTablesKeys(diagramData.nodes).toSet
    // }

    import com.dalineage.common.adt.ADT

    def codePosition(optPos: Option[ADT.Range]): Json =
      optPos match
        case None => Json.obj(
            "sourceId" -> Json.Null,
            "linefrom" -> Json.Null,
            "columnfrom" -> Json.Null,
            "lineto" -> Json.Null,
            "columnto" -> Json.Null,
            "tooltip" -> Json.fromString("Code block: not available")
          )
        case Some(ADT.Range(scid, ADT.Position(lf, cf), ADT.Position(lt, ct))) =>
          Json.obj(
            "sourceId" -> Json.fromInt(scid),
            "linefrom" -> Json.fromInt(lf),
            "columnfrom" -> Json.fromInt(cf),
            "lineto" -> Json.fromInt(lt),
            "columnto" -> Json.fromInt(ct),
            "tooltip" -> Json.fromString(
              s"\nCode block:\nSource ID: $scid\nStart line/column: $lf / $cf\nEnd line/column:   $lt /  $ct\n(click to select in code/property window)\n"
            )
          )

    def addCodePosition(optPos: Option[ADT.Range])(node: Json): Json =
      codePosition(optPos)
        .deepMerge(node)

    private def groupToModel(
        group: Group,
        optGroup: Option[NodeKey],
        nodeScore: Map[NodeKey, Double]
      ): List[Json] = {

      val groupKey = "GROUP_" + group.key

      val groupNode: Json = Json.obj(
        "type" -> Json.fromString("Diagram group"),
        "key" -> Json.fromString(groupKey),
        "caption" -> Json.fromString(group.caption),
        "visible" -> Json.fromBoolean(true),
        // "visible" -> Json.fromBoolean(optGroup.map(_ => false).getOrElse(true)),
        "isGroup" -> Json.fromBoolean(true),
        "group" -> optGroup.map(Json.fromString).getOrElse(Json.Null),
        "expanded" -> Json.fromBoolean(group.expanded)
      )
        .pipe(addCodePosition(group.codePosition))
        .dropNullValues

      groupNode :: sortNodes(group.nodes, nodeScore).flatMap {
        case g: Group => groupToModel(g, Some(groupKey), nodeScore)
        case t: Table => tableModel(t, Some(groupKey)) :: Nil
      }
    }

    private def columnModel(
        column: Column,
        indent: Int,
        tableKey: String
      ): Json = {
      val struct: List[Json] = column.struct.map(c => columnModel(c, indent + 1, tableKey))
      val isStruct: Boolean = column.struct.size > 0
      val expanded: Boolean = column.clas match {
        case c if c.contains(ExpandedStruct)  => true
        case c if c.contains(CollapsedStruct) => false
        case _                                => false // who cares
      }
      Json.obj(
        "type" -> Json.fromString("Lineage projection column"),
        "key" -> Json.fromString(column.key),
        "name" -> Json.fromString(column.caption),
        "color" -> Json.fromString(columnColor(column.clas)),
        "isstruct" -> Json.fromBoolean(isStruct),
        "expanded" -> Json.fromBoolean(expanded),
        "tablekey" -> Json.fromString(tableKey),
        "figure" -> Json.fromString(figure(column.clas)),
        "indent" -> Json.fromInt(indent * 20),
        "struct" -> Json.fromValues(struct)
      )
    }

    private def figure(clas: Set[ColumnClass]): String = clas match {
      case c if c.contains(DatabaseColumnAutocreated) => "Triangle"
      case c if c.contains(DatabaseColumn)            => "Rectangle"
      case c if c.contains(StructDatatype)            => "TriangleRight"
      case c if c.contains(ConstantSource)            => "Diamond"
      case c if c.contains(UnresolvedSource)          => "Triangle"
      case c if c.contains(ExpandedStruct)            => "Triangle"
      case c if c.contains(CollapsedStruct)           => "Triangle"
      case _                                          => "Circle"
    }

    private def columnColor(clas: Set[ColumnClass]): String = clas match {
      case c if c.contains(DatabaseColumnAutocreated) => "yellow"
      case c if c.contains(DatabaseColumn)            => "green"
      case c if c.contains(StructDatatype)            => "lightblue"
      case c if c.contains(ConstantSource)            => "white"
      case c if c.contains(UnresolvedSource)          => "red"
      case c if c.contains(ExpandedStruct)            => "white"
      case c if c.contains(CollapsedStruct)           => "white"
      case _                                          => "white"
    }

    private def tableModel(node: Table, optGroup: Option[NodeKey]): Json = {
      val columnData: List[Json] = node.columns.map(c => columnModel(c, 0, node.key))

      val tableType: String = node.clas match {
        case c if c(ConstantList)  => "List of constants"
        case c if c(VariableList)  => "List of variables"
        case c if c(DatabaseTable) => "Database table"
        case _                     => "Detailed lineage table"
      }

      Json.obj(
        "type" -> Json.fromString(tableType),
        "key" -> Json.fromString(node.key),
        "name" -> Json.fromString(node.caption),
        "visible" -> Json.fromBoolean(true),
        // "visible" -> Json.fromBoolean(optGroup.map(_ => false).getOrElse(true)),
        "fields" -> Json.fromValues(columnData),
        "caption" -> Json.fromString(node.caption),
        "group" -> optGroup.map(Json.fromString).getOrElse(Json.Null),
        "expanded" -> Json.fromBoolean(node.expanded),
        "sourceId" -> node.codePosition.map(_.sourceId).map(sourceId =>
          Json.fromInt(sourceId)
        ).getOrElse(Json.Null),
        "linefrom" -> node.codePosition.map(_.from.line).map(fromLine =>
          Json.fromInt(fromLine)
        ).getOrElse(Json.Null),
        "columnfrom" -> node.codePosition.map(_.from.column).map(fromCol =>
          Json.fromInt(fromCol)
        ).getOrElse(Json.Null),
        "lineto" -> node.codePosition.map(_.to.line).map(toLine =>
          Json.fromInt(toLine)
        ).getOrElse(Json.Null),
        "columnto" -> node.codePosition.map(_.to.column).map(toCol =>
          Json.fromInt(toCol)
        ).getOrElse(Json.Null)
      )
        .pipe(addCodePosition(node.codePosition))
        .dropNullValues
    }

    // private def linksOfCollapsedTables(
    //     relationData: List[Json],
    //     collapsedTables: Set[String]
    //   )(fromOrTo: String
    //   ): Json =
    //   relationData
    //     .filter(sd =>
    //       collapsedTables.contains(sd.hcursor.get[String](fromOrTo).toOption.getOrElse(""))
    //     )
    //     .groupBy(_.hcursor.get[String](fromOrTo).getOrElse(""))
    //     .toSeq
    //     .map { case (a, b) => Json.obj(a -> Json.fromValues(b)) }
    //     .foldRight(Json.obj()) { case (json, acc) => acc.deepMerge(json) }

    def toNodeDataArray: List[Json] = {
      val score = diagramData.nodeScore
      sortNodes(diagramData.nodes, score)
        .flatMap {
          case t: Table => tableModel(t, None) :: Nil
          case g: Group => groupToModel(g, None, score)
        }
    }

    def toLinkDataArray = {
      // val collapsedTables = diagramData.collapsedTables

      diagramData.relations
        .toList
        // .map(relationModel(_, collapsedTables))
        .map(relationModel(_))
    }

    def toGoJS(batchInfo: Option[BatchInfo] = None): Json = {
      val relationData = toLinkDataArray
      Json.obj(
        "class" -> Json.fromString("GraphLinksModel"),
        "copiesArrays" -> Json.fromBoolean(true),
        "copiesArrayObjects" -> Json.fromBoolean(true),
        "linkFromPortIdProperty" -> Json.fromString("fromPort"),
        "linkToPortIdProperty" -> Json.fromString("toPort"),
        // "modelData" -> Json.obj(
        //   batchInfo.map(bi => "batchId" -> Json.fromString(bi.id)).getOrElse("batchId" -> Json.Null),
        //   batchInfo.map(bi => "user" -> Json.fromString(bi.user)).getOrElse("batchId" -> Json.Null),
        //   "cache" -> Json.obj(),
        //   "linksFromCollapsedTables" -> linksOfCollapsedTables(relationData, collapsedTables)("from"),
        //   "linksToCollapsedTables" -> linksOfCollapsedTables(relationData, collapsedTables)("to")
        // ),
        "nodeDataArray" -> Json.fromValues(toNodeDataArray),
        "linkDataArray" -> Json.fromValues(relationData)
      )
    }
  }
}
