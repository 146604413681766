/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import org.scalajs.dom
import org.scalajs.dom.document
import org.scalajs.dom.html.Div
import org.scalajs.dom.html
import org.scalajs.dom.window
import org.scalajs.dom.KeyboardEvent
import org.scalajs.dom.console
import scala.concurrent.ExecutionContext.Implicits.global
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import js.timers._

import io.circe._, io.circe.parser._, io.circe.syntax._

import com.dalineage.client
import client.IDBDatabaseUtils._

import UserActions.UserAction
import WorkspaceUserActions._
import LineageUserActions._
import ExplorerUserActions._

import com.dalineage.common
import common.adt.ExplorerDataADT._
import common.adt.DiagramADT._
import common.Batches._

import Window._
import scala.concurrent.Future
import com.dalineage.common.DiagramDataSerializer.GoJSSerializer
import scala.util.{Success, Failure}
import scala.util.chaining._
import org.scalablytyped.runtime.StringDictionary
import cats.syntax.all._

object VisualizationApp {

  val msgBox: String => Unit = { msg =>
    Console.msgBox(msg)
  }

  def main(args: Array[String]): Unit = {

    val mainDiv = document.getElementById("main").asInstanceOf[Div]
    mainDiv.innerHTML = ""
    val params = DomOps.parseQueryString()

    DomOps.sendRequest(
    dom.HttpMethod.GET, "web/current_user", { user =>
      js.Dynamic.global.user = user
      params.get("page") match {
        case Some("old") =>
          val userActionFn: UserAction => Unit = { action => action match {
            case OpenBatch(user, batchId, optViewId) =>
              optViewId match {
                case None => showLineage(user, batchId)
                case Some(viewId) =>
                  val fn:UserAction => Unit = { action =>
                    action match {
                      case Editor.SelectCode(linefrom, columnfrom, lineto, columnto) =>
                        Editor.selectCode("0", linefrom, columnfrom, lineto, columnto)
                    }
                  }
                  showView(s"$batchId/$viewId", fn)
              }
              loadCode(user, batchId, 0)
              old.WorkspaceWindow.resize()
            case DeleteDir(user, dirs) => deleteDir(user, dirs)
            case DeleteBatch(batchId, None) => deleteBatch(batchId)
            case Logout() => logout()
            case ReuploadAllBatches() => reuploadAll()
            case DeleteBatch(batchId, Some(viewId)) => deleteView(batchId, viewId)
            case ReuploadBatch(user, batchId) => reuploadBatch(user, batchId)
            case RenameView(batchId, viewId, newName) => renameView(batchId, viewId, newName)
            case ShowExplorer() => loadExplorerData({ data =>
              old.explorer.Explorer.open(data)
              old.WorkspaceWindow.resize()
            })
            case ke:KeyboardEvent => old.Workspace.userActionFn(ke)
          }}

          old.Workspace.init(mainDiv, userActionFn)

        case Some("diagram") =>

          mainDiv.style.width = s"${dom.window.innerWidth}px"
          mainDiv.style.height = s"${dom.window.innerHeight}px"

          val widthBorder = 15
          val heightBorder = 23

          params.get("id") match
            case None => mainDiv.innerHTML = "id is missing"
            case Some(id) => params.get("user") match
              case None => mainDiv.innerHTML = "user is missing"
              case Some(user) =>
                val userActionFn: UserAction => Unit = { action =>
                  action match
                    case UserActions.KeyboardEvent(ev) => mainDiv.dispatchEvent(ev)
                    case _ => println(s"action ${action}")
                }
                val diagramWindow = Window.SingleWindow()
                diagramWindow.updateHelpText("Diagram help")
                diagramWindow.init(mainDiv)
                diagram.GoJSDiagram.init(diagramWindow.contentDiv, userActionFn)
                val fn: DiagramData => Unit = {
                  dd =>
                    diagram.GoJSDiagram.open(dd)
                    diagramWindow.resize(dom.window.innerWidth - widthBorder, dom.window.innerHeight - heightBorder)
                    dom.window.onresize = { (e: dom.Event) =>
                      def w = dom.window.innerWidth - widthBorder
                      def h = dom.window.innerHeight - heightBorder
                      diagramWindow.resize(w, h)
                    }
                }
                val url = s"web/batch/$user/$id"
                DomOps.getJsonObject[DiagramData](url, fn)

        case Some("explorer") =>
          val widthBorder = 15
          val heightBorder = 23

          def w = dom.window.innerWidth - widthBorder
          def h = dom.window.innerHeight - heightBorder

          val userActionFn: UserAction => Unit = { action =>
            action match
              case UserActions.KeyboardEvent(ev) => dom.document.body.dispatchEvent(ev)
              case _ => println(s"action ${action}")
          }
          mainDiv.style.width = s"${dom.window.innerWidth}px"
          mainDiv.style.height = s"${dom.window.innerHeight}px"

          val batchTreeWindow = Window.SingleWindow()
          batchTreeWindow.updateHelpText( "Select batch" )
          val explorerWindow =
            Window.WindowPair( batchTreeWindow, PropertyPanel.propertyWindow)
          val msg = s"property page help"
          explorerWindow.updateHelpText(msg)

          loadExplorerData({ data =>
            BatchTree.open(data)
            explorerWindow.resize(w, h)
            dom.window.onresize = { (e: dom.Event) =>
              explorerWindow.resize(w, h)
            }
          })

        case Some("batchtree") =>
          val userActionFn: UserAction => Unit = { action =>
            action match
              case UserActions.KeyboardEvent(ev) => dom.document.body.dispatchEvent(ev)
              case _ => println(s"action ${action}")
          }

          mainDiv.style.width = s"${dom.window.innerWidth}px"
          mainDiv.style.height = s"${dom.window.innerHeight}px"

          val widthBorder = 15
          val heightBorder = 23

          val batchTreeWindow = Window.SingleWindow()
          batchTreeWindow.updateHelpText("Batch tree help")
          batchTreeWindow.init(mainDiv)
          BatchTree.init(batchTreeWindow, userActionFn)

          dom.document.addEventListener("keydown", { (event: dom.KeyboardEvent) =>
            val wsevent = UserActions.KeyboardEvent(event)
            BatchTree.userActionFn( wsevent )
          })

          loadExplorerData({ data =>
            BatchTree.open(data)
            batchTreeWindow.resize(dom.window.innerWidth - widthBorder, dom.window.innerHeight - heightBorder)
            dom.window.onresize = { (e: dom.Event) =>
              def w = dom.window.innerWidth - widthBorder
              def h = dom.window.innerHeight - heightBorder
              batchTreeWindow.resize(w, h)
            }
          })

        case None =>
          //former https://localhost/?page=workspace

          val userActionFn: UserAction => Unit = { action =>
            action match {
              case UserActions.LoadFile(url, fn) => DomOps.getFile(url, fn)
              case UserActions.LoadJson(url, fn) => DomOps.getJson(url, fn)
              case UserActions.LoadExplorerData(fn) => loadExplorerData(fn)
              case UserActions.KeyboardEvent(event) => mainDiv.dispatchEvent(event)
              case DeleteDir(user, dirs) => deleteDir(user, dirs)
              case DeleteBatch(batchId, None) => deleteBatch(batchId)
              case DeleteBatch(batchId, Some(viewId)) => deleteView(batchId, viewId)
              case Logout() => logout()
              case _ => println(s"unhandled action " + pprint.apply(action))
            }}

          Workspace.init(mainDiv, userActionFn, params)

        case Some(_) => throw new Exception(s"assertion failed") //TBD 404
      }
    },
    noCache = true)
  }

  def logout(): Unit =
    if (s"${js.Dynamic.global.user}" == "public")
      msgBox(s"Redirecting to login page")
      window.location.replace("/login")
    else
      DomOps.sendRequest(dom.HttpMethod.GET, "web/logout", { text =>
        msgBox(s"Logged out, response: $text")
        DomOps.sendRequest(
          dom.HttpMethod.GET,
          "web/current_user",
          { user =>
            js.Dynamic.global.user = user
            msgBox(s"Logged out, current user is now $user")
            document.location.reload()
          },
          noCache = true
        )
      })

  def reuploadAll(): Unit = {
    val reupload = dom.window.prompt("Please type exactly: reupload")
    if (reupload == "reupload") {
      DomOps.sendRequest(dom.HttpMethod.GET, "web/reupload_all", { text => msgBox(s"Reupload response: $text") })
      msgBox("Reupload request sent")
    } else msgBox("Reupload failed")
  }

  def loadExplorerData(successFn: ExplorerData => Unit): Unit = {
    val dFn: ExplorerData => Unit = { data =>
      msgBox("User directory data loaded")
      successFn(data)
    }
    DomOps.getJsonObject[ExplorerData]( "web/dashboard", dFn )
  }

  var intervalHandler: SetIntervalHandle = null

  def deleteDir(user: String, dirs: List[String]): Unit = {
    if (dirs.isEmpty) return
    val path = s"web/dir/$user?dirs=${dirs.mkString(",")}"
    msgBox(s"Sending delete dir request, path $path")
    DomOps.sendRequest(dom.HttpMethod.DELETE, path)
  }

  def deleteBatch(batchId: String): Unit = {
    val path = s"web/batch/$batchId"
    msgBox(s"Sending delete batch request, batchId $batchId")
    DomOps.sendRequest(dom.HttpMethod.DELETE, path)
  }

  def renameView(batchId: String, viewId: String, newName: String): Unit = {
    val path = s"web/view/$batchId/$viewId/$newName"
    msgBox(s"Sending rename to $newName request, batchId/viewId $batchId/$viewId")
    DomOps.sendRequest(dom.HttpMethod.PATCH, path)
  }

  def reuploadBatch(user: String, batchId: String): Unit = {
    val path = s"web/reupload/$user/$batchId"
    msgBox("Sending reupload request, batchId $batchId")
    DomOps.sendRequest(dom.HttpMethod.GET, path)
  }

  def deleteView(batchId: String, viewId: String): Unit = {
    val path = s"web/view/$batchId/$viewId"
    msgBox(s"Sending delete view request, batchId/viewId $batchId/$viewId")
    DomOps.sendRequest(dom.HttpMethod.DELETE, path)
  }

  def setLineageTimer(id: String): Unit = {
    Option(intervalHandler).map( clearInterval )
    intervalHandler = setInterval(1000) {
      if (old.lineage.Lineage.model != old.lineage.Lineage.lastModel) {
        DomOps.postJson(old.lineage.Lineage.model, s"web/view/$id")
        old.lineage.Lineage.lastModel = old.lineage.Lineage.model
      }
    }
  }

  def showView(viewPath:String, userActionFn: UserAction => Unit): Unit = {
    val batchId = viewPath.split("/")(0)
    val lFn: String => Unit = { json =>
      msgBox(s"Lineage view loaded: $viewPath")
      old.lineage.Lineage.showView(json, userActionFn)
    }
    DomOps.getFile(s"web/view/$viewPath",lFn)
    setLineageTimer(batchId)
  }

  def loadCode(user: String, batchId: String, sourceId: Int): Unit = {
    val lFn: String => Unit = {
      code =>
        old.Editor.code = code
        msgBox(s"Source code loaded, batchId $batchId sourceId $sourceId")
        old.EditorWindow.viewCode()
    }
    DomOps.getFile(s"web/code/$user/$batchId/$sourceId",lFn)
  }

  def showLineage(user: String, id: String): Unit = {
    val lFn: DiagramData => Unit = { diagramData =>
      msgBox(s"Lineage loaded, user $user id $id")

      val fn: UserAction => Unit = { action =>
        action match {
          case Editor.SelectCode(linefrom, columnfrom, lineto, columnto) =>
            old.Editor.selectCode(linefrom, columnfrom, lineto, columnto)
          case UserActions.KeyboardEvent(ev) => println(s"showLineage: Keyboard event $ev")
        }
      }

      Future.successful(diagramData.toGoJS())
        .flatTap(com.dalineage.client.IDBDatabaseService.addLineage(ignoreConstraintError = true))
        .>>(for {
          nodes <- com.dalineage.client.IDBDatabaseService.getNodes(false)
          links <- com.dalineage.client.IDBDatabaseService.getUniqueLinks(false)
        } yield (nodes.toJSArray, links.toJSArray))
        .map{ case (nodes, links) =>
          Json.obj(
          "class" -> Json.fromString("GraphLinksModel"),
          "copiesArrays" -> Json.fromBoolean(true),
          "copiesArrayObjects" -> Json.fromBoolean(true),
          "linkFromPortIdProperty" -> Json.fromString("fromPort"),
          "linkToPortIdProperty" -> Json.fromString("toPort"),
          "modelData" -> Json.obj()
          )
          .noSpaces
          .pipe(typings.gojs.mod.Model.fromJson(_).asInstanceOf[typings.gojs.mod.GraphLinksModel])
          .tap(_.addLinkDataCollection(links))
          .tap(_.addNodeDataCollection(nodes))
        }
        .map(old.lineage.Lineage.showView(_, fn))
        .andThen{
          case Failure(exception) => console.error(s"Show Lineage finished with error message: ${exception.getMessage}")
          case Success(result) => console.log(s"Show Lineage finished with success.")
        }

    }
    DomOps.getJsonObject(s"web/batch/$user/$id", lFn)
    setLineageTimer(id)
  }
}
