/* (c) Miroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.client

import scala.collection.immutable._
import com.dalineage.common
import org.scalajs.dom
import org.scalablytyped.runtime.StringDictionary

object UserActions {
  import io.circe.Json
  import common.adt.ExplorerDataADT._

  trait UserAction
  trait ToolboxUserAction extends UserAction {
    def key = this.getClass.getSimpleName
  }

  case class KeyboardEvent(event: dom.KeyboardEvent) extends UserAction

  case class LoadFile(url: String, fn: String => Unit) extends UserAction
  case class LoadJson(url: String, fn: Json => Unit) extends UserAction
  case class LoadExplorerData(fn: ExplorerData => Unit) extends UserAction

  object Explorer {
    case class ExplorerNodeSelected(selected: Option[StringDictionary[_]]) extends UserAction
  }
}

object WorkspaceUserActions {
  import UserActions._

  case class OpenBatch(user:String, id: String, viewId: Option[String]) extends UserAction

  case class OpenView(batchId: String, id: String) extends UserAction

  case class ShowConsole() extends ToolboxUserAction

  case class ShowExplorer() extends ToolboxUserAction

  case class ShowUploadBatch() extends ToolboxUserAction

  case class ShowUploadCode() extends ToolboxUserAction

  case class ShowUploadSnippetZip() extends ToolboxUserAction

  case class Logout() extends ToolboxUserAction

  case class ReuploadAllBatches() extends ToolboxUserAction

  case class RenameView(batchId: String, viewId: String, newName: String) extends ToolboxUserAction

  case class ReuploadBatch(user: String, batchId: String) extends ToolboxUserAction

  case class DeleteBatch(batchId: String, optViewId: Option[String]) extends ToolboxUserAction

  case class DeleteDir(user: String, dir: List[String]) extends ToolboxUserAction
}

object LineageUserActions {
  import UserActions._

  case class ShowEditor() extends ToolboxUserAction

  case class GridLayout() extends ToolboxUserAction

  case class CircularLayout() extends ToolboxUserAction

  case class LayeredDigraphLayout() extends ToolboxUserAction

  case class RestrictLineage() extends ToolboxUserAction
}

object ExplorerUserActions {
  import UserActions._

  case class LoadBatch() extends ToolboxUserAction

  case class LoadBatchClose() extends ToolboxUserAction

  case class RefreshExplorer() extends ToolboxUserAction

  case class DeleteSelected() extends ToolboxUserAction

  case class RenameSelectedView() extends ToolboxUserAction

  case class DownloadOriginal() extends ToolboxUserAction

  case class ReuploadSelectedBatch() extends ToolboxUserAction

  case class CSVLineage() extends ToolboxUserAction
}
