package com.dalineage.client.diagram

import org.scalajs.dom
import scala.scalajs.js.JSConverters._

import typings.gojs.{mod => go}

import com.dalineage.common
import common.adt.DiagramADT.DiagramData

import com.dalineage.client
import client.UserActions.UserAction
import client.diagram.DiagramOps

import org.scalablytyped.runtime.StringDictionary
import com.dalineage.common.DiagramDataSerializer.GoJSSerializer

import com.dalineage.client.{ diagram => gojsdiagram }

import com.dalineage.common.DiagramDataSerializer.GoJSSerializer
import scala.util.chaining._
import scala.util.{Success, Failure}
import scala.concurrent.Future
import scala.concurrent.ExecutionContext.Implicits.global
import io.circe.Json
import cats.syntax.all._
import client.IDBDatabaseUtils._

import client.Window._
import io.circe

object GoJSDiagram {

  case class DiagramNodeSelected(selected: Option[StringDictionary[_]]) extends UserAction
  case class DiagramLinkSelected(selected: Option[go.Link]) extends UserAction
  case class ColumnSelected(selected: Option[(StringDictionary[_],go.Panel)]) extends UserAction
  case class ModelChanged(modelJson: String) extends UserAction

  val diagramWindow = SingleWindow()

  var diagram: go.Diagram = null

  var diagramDiv: dom.html.Div = null

  var model: String = null

  var selectedColumn: Option[(StringDictionary[_],go.Panel)] = None

  def gridLayout(): Unit = setLayout(new go.GridLayout)
  def layeredDigraphLayout(): Unit = setLayout(new go.LayeredDigraphLayout)
  def circularLayout(): Unit = setLayout(new go.CircularLayout)

  def setLayout(layout: go.Layout): Unit = {
    val selection = diagram.selection
    selection.size match {
      case 0 => diagram.layout = layout
      case _ =>
        selection.map{ _ match {
          case group:go.Group => group.layout = layout
          case ee @ _ => println(s"W: no group selected!")
        }}
    }
  }

  var userActionFn: UserAction => Unit = null

  def open(user: String, id: String, userActionFn: UserAction => Unit): Unit = {

    val lFn: circe.Json => Unit = { json =>
      json.as[DiagramData] match {
        case Left(err) => client.Console.msgBox(s"Error decoding diagram data JSON: " + "\n" + err)
        case Right(dd) =>
          client.Console.msgBox(s"Lineage loaded, user $user id $id")

          Future.successful(dd.toGoJS())
            .map(_.tap(_ => init(client.diagram.GoJSDiagram.diagramWindow.div, userActionFn)))
            .flatTap(
              _.pipe(com.dalineage.client.IDBDatabaseService.addLineage(ignoreConstraintError = true))
              .andThen{
                case Failure(exception) => dom.console.error(s"Error adding nodes: ${exception.getMessage}")
                case Success(result) => dom.console.log(s"Nodes added: ${result}")
              }
            )
            .flatMap(_ => open())
      }
    }

    userActionFn( client.UserActions.LoadJson(s"web/batch/$user/$id",lFn) )
  }

  def open(): Future[Unit] =
    (for {
      nodes <- com.dalineage.client.IDBDatabaseService.getNodes(false)
      links <- com.dalineage.client.IDBDatabaseService.getUniqueLinks(false)
    } yield (nodes.toJSArray, links.toJSArray))
    .map{ case (nodes, links) =>
      Json.obj(
      "class" -> Json.fromString("GraphLinksModel"),
      "copiesArrays" -> Json.fromBoolean(true),
      "copiesArrayObjects" -> Json.fromBoolean(true),
      "linkFromPortIdProperty" -> Json.fromString("fromPort"),
      "linkToPortIdProperty" -> Json.fromString("toPort"),
      "modelData" -> Json.obj()
      )
      .noSpaces
      .pipe(go.Model.fromJson(_).asInstanceOf[go.GraphLinksModel])
      .tap(_.addLinkDataCollection(links))
      .tap(_.addNodeDataCollection(nodes))
    }
    .map(showModel(_))

  def open(diagramData: DiagramData): Unit = {
    // val collapsedTables: Set[String] = diagramData.collapsedTables
    val modelJson: String = diagramData.toGoJS().noSpaces
    val model: go.GraphLinksModel = go.Model.fromJson(modelJson).asInstanceOf[go.GraphLinksModel]
    showModel(model)
  }

  def init(div: dom.html.Div, userActionFn: UserAction => Unit): Unit = {
    diagramDiv = div

    this.userActionFn = userActionFn
    assert(diagramDiv != null, "Diagram div not initialized!")
    if( diagram == null ) {
      diagram = new go.Diagram(diagramDiv)
    }
  }

  def highlightColumn(col: go.Panel, hi: Boolean)(implicit diagram: go.Diagram): Unit = {
    col.background = if( hi ) "dodgerblue" else "white"
  }

  def showModel(model: go.Model): Unit = {

    val diagramUserActionFn: UserAction => Unit = { action =>
      action match {
        case DiagramNodeSelected(optData) =>
          optData.map{ data =>
            selectedColumn.map{ case (_, col) => highlightColumn(col, false)(diagram) }
            selectedColumn = None

            val key = data.get("key").toString
            if( data.get("linefrom").isDefined ) {
              val sourceId = data.get("sourceId").get.toString
              val linefrom: Int = data.get("linefrom").get.toString.toInt
              val columnfrom: Int = data.get("columnfrom").get.toString.toInt
              val lineto: Int = data.get("lineto").get.toString.toInt
              val columnto: Int = data.get("columnto").get.toString.toInt
              client.Editor.selectCode(sourceId, linefrom, columnfrom, lineto, columnto)
            }
          }
          userActionFn(action)

        case ColumnSelected(optData) =>
          selectedColumn.map{ case (_, col) => highlightColumn(col, false)(diagram) }
          selectedColumn = optData
          optData.map{ case (_, col) => highlightColumn(col, true)(diagram) }
          optData.map{ _ => diagram.clearSelection() }

          userActionFn(action)

        case client.UserActions.KeyboardEvent(event) if event.keyCode == 13 =>
          val selectedParts = diagram.selection
          selectedParts.each { part =>
            part match
              case group: go.Group =>
                if (group.isSubGraphExpanded)
                  val nodes = group.memberParts.filter(_.isInstanceOf[go.Node])
                  nodes.first() match
                    case node: go.Node =>
                      diagram.select(node)
                    case e @ _ =>
                else
                  group.isSubGraphExpanded = true
              case tbl: go.Node =>
                val data = tbl.data.asInstanceOf[StringDictionary[_]]
                data.get("visible") match
                  case Some(visible) =>
                    if (visible.asInstanceOf[Boolean])
                      //TBD enter first column
                      tbl.findObject("COLUMNLIST").visible = false
                      data.update("visible", false)
                    else
                      tbl.findObject("COLUMNLIST").visible = true
                      data.update("visible", true)
                  case None =>
          }
        case client.UserActions.KeyboardEvent(event) if event.keyCode == 32 =>
          val selectedParts = diagram.selection
          selectedParts.each { part =>
            part match
              case group: go.Group =>
                group.isSubGraphExpanded = !group.isSubGraphExpanded
              case tbl: go.Node =>
                val data = tbl.data.asInstanceOf[StringDictionary[_]]
                data.get("visible") match
                  case Some(visible) =>
                    val newVisible = !visible.asInstanceOf[Boolean]
                    tbl.findObject("COLUMNLIST").visible = newVisible
                    data.update("visible", newVisible)
                  case None =>
          }

        case client.UserActions.KeyboardEvent(event) if event.keyCode == 27 =>
          val selectedPart = diagram.selection.first()
          if (selectedPart != null)
            val data = selectedPart.data.asInstanceOf[StringDictionary[_]]
            data.get("group") match
              case Some(parent) =>
                val parentPart = diagram.findNodeForKey(parent.asInstanceOf[String])
                diagram.select(parentPart)
              case None =>


        case client.UserActions.KeyboardEvent(event)  =>
          val selectedPart = diagram.selection.first()
          val e = diagram.lastInput.event.asInstanceOf[dom.KeyboardEvent]
          if (selectedPart != null) {
            val newPart: Option[go.Part] = e.key match {
              case "ArrowUp"    =>
                Some(findNearestNode(selectedPart, "up"))
              case "ArrowDown"  =>
                Some(findNearestNode(selectedPart, "down"))
              case "ArrowLeft"  =>
                Some(findNearestNode(selectedPart, "left"))
              case "ArrowRight" =>
                Some(findNearestNode(selectedPart, "right"))
              case _ =>
                None
            }
            newPart match
              case Some(part) =>
                diagram.select(part)
                diagram.scrollToRect(part.actualBounds)
                e.preventDefault()
              case None =>
                userActionFn(client.UserActions.KeyboardEvent(e))
          } else {
            //tbd filter out keys, enter, esc etc?
            userActionFn(client.UserActions.KeyboardEvent(e))
          }

        //case UserActions.ModelChanged(modelJson) =>

        case _ => userActionFn(action)
      }
    }

    diagram.maxSelectionCount = 1

    diagram.nodeTemplate = LineageTemplate(diagramUserActionFn)(diagram)

    diagram.groupTemplate = GroupTemplate(diagramUserActionFn)

    diagram.linkTemplate = LinkTemplate()

    diagram.model = model
  }

  // Helper function to find the nearest node in a given direction
  def findNearestNode(currentPart: go.Part, direction: String): go.Part = {
    val diagram = currentPart.diagram
    val currentBounds = currentPart.actualBounds
    var nearestPart: go.Part = null
    var nearestDistance = Double.MaxValue

    diagram.nodes.each { part =>
      if (part != currentPart) {
        val partBounds = part.actualBounds
        var distance = Double.MaxValue
        direction match {
          case "up" =>
            if (partBounds.bottom < currentBounds.top) {
              distance = currentBounds.top - partBounds.bottom
            }
          case "down" =>
            if (partBounds.top > currentBounds.bottom) {
              distance = partBounds.top - currentBounds.bottom
            }
          case "left" =>
            if (partBounds.right < currentBounds.left) {
              distance = currentBounds.left - partBounds.right
            }
          case "right" =>
            if (partBounds.left > currentBounds.right) {
              distance = partBounds.left - currentBounds.right
            }
        }
        if (distance < nearestDistance) {
          nearestDistance = distance
          nearestPart = part
        }
      }
    }
    nearestPart
  }
}
