/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client.objectbrowser

import io.circe

import com.dalineage.common
import common.adt.ADT

import com.dalineage.client

import client.UserActions.UserAction

import scala.concurrent.Future
import scala.concurrent.Promise
import scala.concurrent.ExecutionContext.Implicits.global
import scala.util.Success
import scala.util.Failure

import org.scalajs.dom
import scala.scalajs.js

import util.chaining._

import upickle.default._

import client
  .{Workspace, Window, TreeExplorer, SearcheableComponent, TreeComponent,
    SelectableComponent, PropertyPanel, Editor, Console
  }

object ObjectBrowser {
  import scala.scalajs.js.JSConverters._
  import common.adt.TreeComponentADT.TreeNode
  import common.adt.TreeComponentADT.ObjectType

  implicit val positionRW: ReadWriter[ADT.Position] = macroRW
  implicit val rangeRW: ReadWriter[ADT.Range] = macroRW
  implicit val treeNodeRW: ReadWriter[TreeNode] = macroRW

  val rootKey = common.adt.TreeComponentADT.rootKey

  case object ErrorMesage extends TreeExplorer.NodeType
  case object Directory extends TreeExplorer.NodeType
  case object DBInstance extends TreeExplorer.NodeType
  case object DBDatabasse extends TreeExplorer.NodeType
  case object DBSchema extends TreeExplorer.NodeType
  case object DBTable extends TreeExplorer.NodeType
  case object DBColumn extends TreeExplorer.NodeType

  val objectBrowserWindow = client.Window.SingleWindow()

  val searchHelpText =
    s"<b>s</b> to ${Workspace.a("search", 83, "Search database objects and source files")}"
  val defaultHelpText = s"Use Arrow keys to navigate, $searchHelpText"
  val showCodeHelpText = s"Press ENTER to view code, $searchHelpText"

  def open(
      user: String,
      batchId: String,
      userActionFn: UserAction => Unit,
      objectBrowserWindow: Window.SingleWindow) : Unit = {
      IndexedDB.setupDatabase(batchId).onComplete {
        case Success(db) =>
          ObjectBrowserData.setKeepAlive(db, batchId)
          dom.window.setInterval(() => ObjectBrowserData.setKeepAlive(batchId), 60 * 1000)
          println(s"database ok, getting objects for $user/$batchId")
          open(db, user, batchId, userActionFn, objectBrowserWindow).onComplete {
            case Success(_) =>
              println(s"Object browser opened")
              objectBrowserWindow.updateHelpText("Object browser data is being loaded...")
              println(s"=== database closed ===")
              db.close()
            case Failure(e) =>
              println(s"Object browser error $e")
          }
        case Failure(e) =>
          println(s"database error $e")
      }
  }

  def open(
      db: dom.IDBDatabase,
      user: String,
      batchId: String,
      userActionFn: UserAction => Unit,
      objectBrowserWindow: Window.SingleWindow) : Future[Unit] = {

    val objectBrowserUserActionFn: UserAction => Unit = { action =>
      action match
        case SearcheableComponent.SelectSearchResult(entry) =>
          val storePrefix = entry.split(":").head match
            case "image object" => "image"
            case "source code" => "sources"
            case e @ _ => throw new Exception(s"Unknown store $e")
          val key = entry.split(":").last
          val splitChar = storePrefix match
            case "image" => "."
            case "sources" => "/"
            case _ => throw new Exception(s"Unknown store $storePrefix")
          val keys = key.split(s"\\$splitChar").toList
          val rootNode = storePrefix match
            case "image" => TreeComponent.rootNodes.head
            case "sources" => TreeComponent.rootNodes.last

          val rootKey = rootNode.getAttribute("data-key")
          SelectableComponent.selectNode(rootNode)
          SelectableComponent.expandSelectedNode().onComplete {
            case Success(()) =>
              TreeComponent.expandNodes(rootNode, keys, splitChar)
              SelectableComponent.expandSelectedNode().onComplete {
                case Success(()) =>
                  objectBrowserWindow.updateHelpText("tbd")
                case Failure(e) =>
                  val msg = s"Search result loading error"
                  println(s"$msg $e")
                  objectBrowserWindow.updateHelpText(msg)
              }
            case Failure(e) =>
              val msg = s"Search result loading error"
              println(s"$msg $e")
              objectBrowserWindow.updateHelpText(msg)
          }
        case SearcheableComponent.SearchText(searchText) =>
          objectBrowserWindow.updateHelpText(s"Starting search $searchText")
          val imageStoreName = ObjectBrowserData.getStoreName("image", batchId)
          val sourceStoreName = ObjectBrowserData.getStoreName("sources", batchId)
          IndexedDB.openDatabase().onComplete {
            case Success(db) =>
              IndexedDB.getMatchingKeys(db, imageStoreName, searchText.toUpperCase).onComplete {
                case Success(keys) =>
                  IndexedDB.getMatchingKeys(db, sourceStoreName, searchText.toUpperCase)
                      .onComplete {
                    case Success(srcKeys) =>
                      val allKeys = keys.map("image object:" + _) ++ srcKeys.map("source code:" + _)
                      objectBrowserWindow
                        .updateHelpText(s"${keys.size}+${srcKeys.size} objects found")
                      SearcheableComponent.setSearchResult(allKeys)
                    case Failure(e) =>
                      println(s"Search error $e")
                      objectBrowserWindow.updateHelpText("Search error")
                  }
                case Failure(e) =>
                  println(s"Search error $e")
                  objectBrowserWindow.updateHelpText("Search error")
              }
              println(s"=== database closed ===")
              db.close()
            case Failure(e) =>
              println(s"Search error $e")
              objectBrowserWindow.updateHelpText("Search error")
          }

        case SelectableComponent.NodeSelected(node) =>
          val properties = node.getAttribute("data-properties")
          if( properties != "")
            PropertyPanel.setProperties(properties)
          val position = node.getAttribute("data-position")
          position match
            case null =>
              objectBrowserWindow.updateHelpText(defaultHelpText)
            case _ =>
              objectBrowserWindow.updateHelpText(showCodeHelpText)
        case SelectableComponent.NodeOpened(node) =>
          val position = node.getAttribute("data-position")
          if( position != null)
            val range = read[ADT.Range](position)
            val sourceId = range.sourceId.toString
            Editor.open(user, batchId, sourceId)
            val lt = range.to.line
            val ct = range.to.column
            if( lt != 0 || ct != 0 )
            {
              val lf = range.from.line
              val cf = range.from.column
              Editor.selectCode(sourceId, lf, cf, lt, ct)
            }
            Console.msgBox(s"Code opened, sourceId $sourceId")
        case _ => userActionFn(action)
    }

    val loadChildrenFnDb: (dom.IDBDatabase,String,String) => Future[Seq[(String,TreeNode)]] =
      (db, storePrefix, key) =>
        println(s"loadChildrenFn $storePrefix $key")
        val promise = Promise[Seq[(String,TreeNode)]]()
        var store = ObjectBrowserData.getStore(db, storePrefix, batchId)
        val storeName = ObjectBrowserData.getStoreName(storePrefix, batchId)
        val request = store.get(key)
        request.onsuccess = { (event: dom.Event) =>

          val result = request.result.asInstanceOf[js.Dynamic]
          val node = read[TreeNode](result.value.asInstanceOf[String])

          val childrenAreLeafs = node.children.get.forall(_.isLeaf)

          val separator = ObjectBrowserData.separator(storePrefix)
          val childrenNodes = node.children.get.map { case node =>
            key == rootKey match
              case true => (node.name, node)
              case false => (s"$key$separator${node.name}", node)
          }


          if (childrenAreLeafs) {
            //i.e. node is database table. children(database columns) are leafs
            promise.success(childrenNodes)
          } else {

            //proceed with loading children
            val childrenKeys = childrenNodes.map(_._1)

            println(
              s"childrenKeys ${childrenKeys.size} ${childrenKeys.take(3).mkString(",") }...")

            val childrenRequest = IndexedDB.getMultipleKeys(db, storeName, childrenKeys)
            childrenRequest.onComplete {
              case Success(childrenResult) =>
                val res = childrenResult.zip(childrenKeys).map {
                  case (child, childKey) =>
                    val value = child.asInstanceOf[js.Dynamic].value

                    val childNode = read[TreeNode](value.asInstanceOf[String])
                    (childKey, childNode)
                }
                promise.success(res.toSeq)
              case Failure(e) =>
                promise.failure(new Exception(e.toString))
            }
          }
        }
        request.onerror = { (event: dom.Event) =>
          promise.failure(new Exception(request.error.toString))
        }
        promise.future

    val loadChildrenFn: (String,String) => Future[Seq[(String,TreeNode)]] =
      (storePrefix, key) =>
        val promise = Promise[Seq[(String,TreeNode)]]()
        IndexedDB.openDatabase().onComplete {
          case Success(db) =>
            loadChildrenFnDb(db, storePrefix, key).onComplete {
              case Success(children) =>
                db.close()
                promise.success(children)
              case Failure(e) =>
                println(s"loadChildrenFn error $e")
                promise.failure(new Exception(e.toString))
            }
          case Failure(e) =>
            println(s"database error $e")
            promise.failure(new Exception(e.toString))
        }
        promise.future

    def init() : Future[Unit] =
      println(s"Initalizing object browser")
      val promise = Promise[Unit]()
      try {
        val mainElement = objectBrowserWindow.contentDiv
        val imageRootNode = TreeComponent.createTreeNode("image", rootKey, "Database image", "")
        val srcRootNode = TreeComponent.createTreeNode("sources", rootKey, "Source codes", "")
        TreeComponent.init(
          loadChildrenFn,
          objectBrowserUserActionFn,
          mainElement,
          imageRootNode :: srcRootNode :: Nil)
        SelectableComponent.init()

        SelectableComponent.selectNode(imageRootNode)
        SelectableComponent.expandSelectedNode().onComplete {
          case Success(()) =>
            objectBrowserWindow.updateHelpText(defaultHelpText)
            println(s"Object browser initialized")
            promise.success(())
          case Failure(e) =>
            val msg = s"Object browser sources downloading error"
            println(s"$msg $e")
            objectBrowserWindow.updateHelpText(msg)
            promise.failure(new Exception(e.toString))
        }
      } catch {
        case e: Throwable =>
          println(s"Error opening object browser window $e")
          promise.failure(new Exception(e.toString))
      }
      promise.future

    ObjectBrowserData.downloadObjectBrowserData(db, user, batchId, objectBrowserWindow)
      .tap { _ => println(s"Object browser data loaded") }
      .flatMap { _ => init() }
  }
}
