/* (c) Miroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.client.old

import scala.collection.immutable._

import com.dalineage.common
import common.adt.DiagramADT._

import com.dalineage.client
import client.old.explorer
import client.UserActions._
import client.WorkspaceUserActions._
import client.LineageUserActions._

import org.scalajs.dom
import dom.Window
import dom.html.Div

import typings.gojs.{mod => go}

object Workspace {

  var userActionFn: UserAction => Unit = null

  def init(main: Div, userActionFn: UserAction => Unit): Unit = {

    val workspaceUserActionFn: UserAction => Unit = { action => action match {
      case GridLayout() => lineage.Lineage.gridLayout()
      case CircularLayout() => lineage.Lineage.circularLayout()
      case LayeredDigraphLayout() => lineage.Lineage.layeredDigraphLayout()
      case RestrictLineage() => lineage.Lineage.restrictLineage()
      case ShowUploadBatch() => Upload.open()
      case ShowUploadCode() => UploadCode.open()
      case ShowUploadSnippetZip() => UploadSnippetZip.open()
      case ShowEditor() => client.old.EditorWindow.open()
      case ShowConsole() => client.Console.open()
      case KeyboardEvent(event) => workspaceKeyboardEvent(event)
      case _ => userActionFn(action)
    }}

    this.userActionFn = workspaceUserActionFn

    dom.window.onkeydown = { (e:dom.KeyboardEvent) =>
      workspaceKeyboardEvent(e)
    }

    WorkspaceWindow.init(main, this.userActionFn)

    dom.window.onresize = { _ =>
      WorkspaceWindow.resize()
    }
    WorkspaceWindow.resize()

    WorkspaceToolbox.init(WorkspaceWindow.toolboxDiv, workspaceUserActionFn)
  }

  def showLineage(dd: DiagramData, userActionFn: UserAction => Unit): Unit = {
    lineage.Lineage.open(dd, userActionFn)
    lineage.Lineage.openToolbox()
    lineage.Lineage.updateToolbox()
  }

  private
  def workspaceKeyboardEvent(e: dom.KeyboardEvent): Unit = {
    if( e.ctrlKey ) {
      e.keyCode match {
        case 79 =>
          this.userActionFn(ShowExplorer())
          e.preventDefault()
        case 68 =>
          this.userActionFn(ShowEditor())
          e.preventDefault()
        case 83 =>
          this.userActionFn(ShowConsole())
          e.preventDefault()
        case 85 =>
          this.userActionFn(ShowUploadCode())
          e.preventDefault()
        case otherCode @ _ =>
          if (explorer.ExplorerWindow.isOpen()) {
            explorer.Explorer.userActionFn(KeyboardEvent(e))
          }
      }
    } else {
      if (explorer.ExplorerWindow.isOpen()) {
        explorer.Explorer.userActionFn(KeyboardEvent(e))
      }
    }
  }

}

//old UI
object WorkspaceWindow extends client.ResizableElement {
  import org.scalajs.dom
  import dom.document
  import dom.html.Div
  import client.CSS

  var diagramDiv: Div = null
  var explorerDiv: Div = null
  var toolboxDiv: Div = null

  override
  def init(rootNode: dom.html.Element, userActionFn: UserAction => Unit): Unit = {
    super.init(rootNode, userActionFn)


    diagramDiv = document.createElement("div").asInstanceOf[Div]
    diagramDiv.id  = "container"
    diagramDiv.style.width = px(600)
    diagramDiv.style.height = px(300)
    diagramDiv.style.backgroundColor = CSS.backgroundColor("lineage-window")
    diagramDiv.style.cssFloat = "left"

    explorerDiv = document.createElement("div").asInstanceOf[Div]
    explorerDiv.id  = "explorer"
    explorerDiv.style.width = px(600)
    explorerDiv.style.height = px(300)
    explorerDiv.style.cssFloat = "left"
    explorerDiv.style.backgroundColor = CSS.backgroundColor("explorer-window")

    toolboxDiv = document.createElement("div").asInstanceOf[Div]
    toolboxDiv.id = "toolbox"
    toolboxDiv.style.width = px(300)
    toolboxDiv.style.height = px(45)
    toolboxDiv.style.backgroundColor = CSS.backgroundColor("toolbox-window")

    rootNode.appendChild(toolboxDiv)
    rootNode.appendChild(diagramDiv)

    lineage.LineageWindow.initToolbox(diagramDiv,userActionFn)
    lineage.LineageWindow.init(diagramDiv,userActionFn)

    assert(!explorer.ExplorerWindow.isOpen()) //init != open
    explorer.Explorer.init(explorerDiv, userActionFn)
    assert(!explorer.ExplorerWindow.isOpen()) //init != open
  }


  def resize(): Unit = {
    assert(rootNode != null)
    resize(dom.window.innerWidth, dom.window.innerHeight)
  }

  override
  def resize(width: Double, height: Double): Unit = {
    val innerWidthBorder = 20
    val innerHeightBorder = 30

    super.resize(width - innerWidthBorder,height - innerHeightBorder)

    val containerWidth = width - innerWidthBorder

    val containerHeight = height - Toolbox.toolboxHeight - innerHeightBorder
    diagramDiv.style.height = px(containerHeight)

    toolboxDiv.style.width =  px(containerWidth)

    if( explorer.ExplorerWindow.isOpen() ) {
      lineage.LineageWindow.resize(containerWidth/2, containerHeight)
      explorer.ExplorerWindow.resize(containerWidth/2, containerHeight)
    } else {
      lineage.LineageWindow.resize(containerWidth, containerHeight)
    }

  }
}
